import styles from "../../../styles/components/FooterRight.module.scss"
import {StoreBasket, StoreCommissions, StoreCurrency, StoreLoadInventory, StoreUser} from "../../../store/store";
import {GetFullNameWallet, ValidateEmail, ValidateQiwi, ValidateTRC20} from "../../../utils/personalData";
import {CreateTrade, GetStatusTrade, getTradeOfferStateData, Sleep} from "../../../utils/Trade";
import {LoadInventory, LoadProfile} from "../../../utils/inventory";
import {useEffect} from "react";


export const FooterRight = () => {
    const Commissions = StoreCommissions((state) => state.commissions)

    const PaymentType = StoreBasket((state) => state.PaymentType)

    const SetIsOpenError = StoreBasket((state) => state.SetIsOpenError)
    const SetErrorText = StoreBasket((state) => state.SetErrorText)
    const SetIsOpenSuccess = StoreBasket((state) => state.SetIsOpenSuccess)
    const SetIsOpenLoader = StoreBasket((state) => state.SetIsOpenLoader)

    const Items = StoreBasket((state) => state.Items)
    const Currency = StoreCurrency((state) => state.Currency)
    const SetTradeId = StoreBasket((state) => state.SetTradeId)
    const SetTradeIdInner = StoreBasket((state) => state.SetTradeIdInner)
    const SetStatus = StoreBasket((state) => state.SetStatus)
    const DeleteAllItems = StoreBasket((state) => state.DeleteAllItems)


    const Qiwi = StoreBasket((state) => state.Qiwi)
    const Trc20 = StoreBasket((state) => state.Trc20)
    const Email = StoreBasket((state) => state.Email)
    const TradeLink = StoreBasket((state) => state.tradeLink)

    const Game = StoreLoadInventory((state) => state.Game)
    const SetErrorLoadingPopUp = StoreLoadInventory((state) => state.SetErrorLoadingPopUp)
    const SetItems = StoreLoadInventory((state) => state.SetItems)
    const SetUserName = StoreLoadInventory((state) => state.SetUserName)
    const SetUserImage = StoreLoadInventory((state) => state.SetUserImage)
    const SetInventoryId = StoreLoadInventory((state) => state.SetInventoryId)

    const SetIsOpenBasket = StoreBasket((state) => state.SetIsOpen)
    const LastActiveBankCard = StoreUser((state) => state.LastActiveBankCard)

    const full = (Items.reduce((accumulator, currentValue) => accumulator + currentValue["Skin Sorter"]["prices"]["median"], 0) * Currency["RUB"]["name_usd"])
    const finish = ((full * Commissions[PaymentType]["percent"]) - (full === 0 ? 0 : Commissions[PaymentType]["fixed commission"]))
    const commission = (full - finish)

    const validateSteamTradeLink = (link) => {
        const regex = /^https:\/\/steamcommunity.com\/tradeoffer\/new\/\?partner=\d+&token=[A-Za-z0-9_-]+$/;
        return regex.test(link);
    };
    const Trade = async () => {
        if (!validateSteamTradeLink(TradeLink)){
            SetIsOpenError(true);
            SetErrorText("У вас не установлена трейд ссылка, установите ее в личном кабинете")
            return
        }
        try {
            SetIsOpenLoader(true)
            const tradeData = await CreateTrade(PaymentType, Qiwi, Trc20, Email, Items, full, finish, commission, TradeLink, LastActiveBankCard);
            if (!tradeData.status) {
                SetIsOpenError(true);
                SetIsOpenLoader(false)
                return;
            }
            else{
                SetIsOpenLoader(false)
                SetIsOpenSuccess(true)
                SetTradeId(tradeData.offers[0]["steam offer id"])
                SetTradeIdInner(tradeData.tradeId)
            }
            while (true) {
                const statusTrade = await GetStatusTrade(tradeData.tradeId);
                if (!statusTrade.status && statusTrade.err === "This trade has already ended"){
                    window.location.reload()
                }
                await Sleep(5000)
            }
        } catch (error) {
            SetIsOpenError(true);
            SetIsOpenLoader(false)
        }
    };

    return (
        <div className={styles.footer_sub_main_basket_right}>
            <div className={styles.sub_footer_sub_main_basket_right_1}>
                <div className={styles.sub_footer_sub_main_basket_right_withdrawal_method}>
                    <span className={styles.sub_footer_sub_main_basket_right_withdrawal_method_1}>Способ вывода средств:</span>
                    <span
                        className={styles.sub_footer_sub_main_basket_right_withdrawal_method_2}>{GetFullNameWallet[PaymentType]}</span>
                </div>
            </div>
            <div className={styles.sub_footer_sub_main_basket_right_2}>
                <div className={styles.sub_footer_sub_main_basket_right_withdrawal_method}>
                    <span className={styles.sub_footer_sub_main_basket_right_withdrawal_method_1}>Комиссия за перевод:</span>
                    <span className={styles.sub_footer_sub_main_basket_right_withdrawal_method_2}>₽ {commission.toFixed(2)}</span>
                </div>
            </div>
            <div className={styles.sub_footer_sub_main_basket_right_3}>
                <div className={styles.sub_footer_sub_main_basket_right_withdrawal_method}>
                                <span
                                    className={styles.sub_footer_sub_main_basket_right_withdrawal_method_13}>Итоговая сумма:</span>
                    <span className={styles.sub_footer_sub_main_basket_right_withdrawal_method_23}>₽ {finish.toFixed(2)}</span>
                </div>
            </div>
            <div className={styles.get_money_block}>
                <span id="logo" className={styles.get_money} onClick={Trade}>Получить деньги</span>
            </div>
        </div>
    )
}