import styles from "../../../styles/components/EmptyBasket.module.scss"
import {HandySvg} from 'handy-svg';
import blue_warning from "../../../assets/svg/blue_warning.svg"

export const EmptyBasket = () => {
    return (
        <div className={styles.sub_main_basket_left_card_empty}>
            <div className={styles.sub_main_basket_left_sub_card_empty}>
                <HandySvg src={blue_warning} className={styles.sub_main_basket_left_sub_card_svg}/>
                <h2 className={styles.sub_main_basket_left_sub_card_h2}>
                    Ваша корзина пуста!
                </h2>
                <span className={styles.sub_main_basket_left_sub_card_span}>
                            Закройте корзину и добавьте товары снова.
                        </span>
            </div>
        </div>
    )
}