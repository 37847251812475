import {HandySvg} from 'handy-svg';
import logo from '../../assets/svg/logo.svg';
import styles from "../../styles/components/Header.module.scss"
import open_select_user from "../../assets/svg/open_select_user.svg"
import {useState} from "react";
import classNames from "classnames";
import {BASIC_URL, BASIC_URL_API} from "../../services/api_constants";
import {HeaderNav} from "./HeaderNav";
import {StoreUser} from "../../store/store";
import {Link} from "react-router-dom";
export const Header = () => {
    const IsAuth = StoreUser((state) => state.IsAuth)
    const SteamData = StoreUser((state) => state.SteamData)
    const [IsOpenNav, setIsOpenNav] = useState(false)
    return (
        <header>
            <nav>
                <div className={styles.sub_nav}>
                    <Link to="/"><HandySvg src={logo} className={styles.svg_nav}/></Link>
                    {IsAuth ? (
                        <section>
                            <div className={styles.nav_user} onClick={() => setIsOpenNav(!IsOpenNav)}>
                                <span className={styles.nav_user_span}>{SteamData["personaname"]}</span>
                                <img src={SteamData["avatarfull"]} className={styles.nav_user_img2} alt=""/>
                                <HandySvg src={open_select_user} className={styles.nav_user_svg}/>
                            </div>
                            {IsOpenNav ? <HeaderNav onMouseLeave={() => setIsOpenNav(false)}/> : null}
                        </section>
                    ) : (
                        <a href={BASIC_URL_API + "login"} className={classNames(styles.open_steam_button, styles.logo)}>Войти через STEAM</a>
                    )}
                </div>
            </nav>
        </header>
    );
}
