import styles from "../../../styles/components/notFound.module.scss"
import {Link} from "react-router-dom";
export const NotFoundBody = () => {
    return (
        <div className={styles.notFoundWrap}>
            <div className={styles.error_body}>
                <span className={styles.error_par}>Error</span>
                <span className={styles.error_404}>404</span>
                <span className={styles.page_not_found_par}>Страница не найдена!</span>
                <span className={styles.return_main}>Вернитесь на главную страницу <br/> и попробуйте снова!</span>
                <Link to="/">
                    <div className={styles.return_main_btn}>
                        <span className={styles.return_main_btn_span}>ВЕРНУТЬСЯ НА ГЛАВНУЮ</span>
                    </div>
                </Link>

            </div>
        </div>
    )
}