import styles from "../../../styles/components/SelectRemoveCard.module.scss"
import {useState} from "react";
import {CardRemove} from "./CardRemove";
import {StoreUser} from "../../../store/store";


const Card = (props) => {
    const [IsOpenBankPopUp, SetIsOpenBankPopUp] = useState(false)
    return (
        <div className={styles.sub_choice_card_2_block}>
            <span className={styles.sub_choice_card_2_block_span_2}>{props.CardId}</span>
            <span className={styles.sub_choice_card_2_block_a_2} onClick={() => SetIsOpenBankPopUp(true)}>удалить</span>
            {IsOpenBankPopUp ? <CardRemove CardId={props.CardId} SetIsOpenBankPopUp={SetIsOpenBankPopUp}/> : null}
        </div>
    )
}


export const SelectRemoveCard = () => {
    const BankCard = StoreUser((state) => state.BankCard)
    return (
        <div className={styles.choice_card}>
            <div className={styles.sub_choice_card_2} >
                {BankCard.map((number, key) =>
                    <Card CardId={number} key={key}/>
                )}
            </div>
        </div>
    )
}