import {UserCard} from "../components/common/lk/UserCard";
import {GameBlock} from "../components/modules/GameBlock";
import styles from "../styles/components/PersonalArea.module.scss"
import {UserInfo} from "../components/common/lk/UserInfo";
import {AccountsRows} from "../components/common/lk/AccountsRows";
import {SendAllReport} from "../components/common/lk/SendAllReport";

export const PersonalArea = () => {
    return (
        <main>
            <UserCard/>
            <GameBlock/>
            <SendAllReport/>
            <div className={styles.add_block}></div>
            <UserInfo/>
            <AccountsRows/>
        </main>
    );
}