import styles from "../../../styles/components/SelectReportAccounts.module.scss"
import {HandySvg} from "handy-svg";
import cancel from "../../../assets/svg/cancel_banner.svg"
import success from "../../../assets/svg/success.svg"
import {Fragment, useEffect, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {StoreBasket, StoreLoadInventory, StoreUser} from "../../../store/store";
import {SaveReportAPI} from "../../../services/api";
import {BASIC_URL_API, ReportAccountsLink} from "../../../services/api_constants";
import {GetAppIdByName} from "../../../utils/inventory";
import {LoadingInventoryPopUp} from "../../modules/LoadingInventory";


export const SelectReportAccountsWrap = () => {
    const SetIsOPen = StoreUser((state) => state.SetIsOpenSelectAccount)
    const IsOPen = StoreUser((state) => state.isOpenSelectAccount)
    return (
        <Transition appear show={IsOPen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => SetIsOPen(false)}>
                <div className="fixed inset-0 overflow-y-auto bg-opacity-40 bg-black">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel>
                                <SelectReportAccounts SetIsOPen={SetIsOPen}/>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

const SelectReportAccounts = ({SetIsOPen}) => {
    const Accounts = StoreUser((state) => state.Accounts)
    const SetIsOpenError = StoreBasket((state) => state.SetIsOpenError)
    const SetErrorText = StoreBasket((state) => state.SetErrorText)
    const Game = StoreLoadInventory((state) => state.Game)
    const [isOpenLoading, setIsOpenLoading] = useState(false)
    const saveAccounts = async () => {
        const change_data = Accounts.map(account => {
            return {
                "steamid": account["steamid"],
                "isCheckedReport": account["isCheckedReport"]
            };
        });
        await SaveReportAPI(change_data)
        const accountsReport = Accounts.filter((account) => account.isCheckedReport);
        if (accountsReport.length === 0) {
            SetIsOpenError(true);
            SetErrorText("Выберите хотя бы один аккаунт")
            return
        }
        if (accountsReport.length > 10) {
            SetIsOpenError(true);
            SetErrorText("Максимум 10 аккаунтов в одном отчете")
            return
        }
        const steamIds = accountsReport.map((account) => account["steamid"]).join(",")
        const link = document.createElement("a");
        link.href = `${BASIC_URL_API}${ReportAccountsLink}?account_ids=${steamIds}&app_id=${GetAppIdByName[Game]}`
        link.download = `ReportAll___${GetAppIdByName[Game]}.xlsx`
        link.addEventListener('load', () => {
            setIsOpenLoading(false);
        });
        link.click();
        setIsOpenLoading(true)
    }


    return (
        <>
            <LoadingInventoryPopUp isOpen={isOpenLoading} setIsOpen={setIsOpenLoading} text={"Процесс создания отчета займет 1-5 мин."}/>
            <section className={styles.select_section}>
                <span className={styles.select_account_span}>Выберите аккаунты</span>
                <HandySvg src={cancel} className={styles.select_cancel_svg} onClick={() => SetIsOPen(false)}></HandySvg>
                <div className={styles.select_accounts_div}>
                    <div className={styles.select_accounts_div_div}>
                        {
                            Accounts.map((item, number) =>
                                <Account item={item} name={item.personaname} profile_url={item.profileurl} avatarfull={item.avatarfull} key={number}/>
                            )
                        }
                    </div>
                </div>
                <button className={styles.select_button} onClick={saveAccounts}>
                    СОЗДАТЬ ОТЧЕТ
                </button>
            </section>
        </>
    )
}

const Account = ({name, profile_url, avatarfull, item}) => {
    const [isCheck, setIsChecked] = useState(item.isCheckedReport)
    useEffect(() => {
        item.isCheckedReport = isCheck
    }, [isCheck])
    return (
        <div className={styles.select_account_div}>
            <img className={styles.select_account_img} src={avatarfull} alt={name}/>
            <a className={styles.select_account_a} href={profile_url} target="_blank">
                <span className={styles.select_account_a_span}>{name}</span>
            </a>
            {isCheck ? <div className={styles.select_account_check_active_div} onClick={() => setIsChecked(false)}>
                <HandySvg src={success} className={styles.select_account_check_div_svg}></HandySvg>
            </div> : <div className={styles.select_account_check_div} onClick={() => setIsChecked(true)}/>}
        </div>
    )
}