import {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {HandySvg} from "handy-svg";
import loadingPopUp from "../../assets/svg/LoadingPopUp.svg"
import styles from "../../styles/components/LoadingInventory.module.scss"
import closePopup from "../../assets/svg/cancel_banner.svg";

export const LoadingInventoryPopUp = ({isOpen, setIsOpen, text}) => {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-20" onClose={() => setIsOpen(false)}>
                <div className="fixed inset-0 overflow-y-auto bg-opacity-70 bg-black">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel>
                                <LoadingInventory setIsOpen={setIsOpen} text={text}/>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}





const LoadingInventory = ({setIsOpen, text}) => {
    return (
        <section className={styles.loading_wrap}>
            <span className={styles.loading_par_span}>Создаем отчет</span>
            <HandySvg src={closePopup} className={styles.loading_close_svg} onClick={() => setIsOpen(false)}/>
            <div className={styles.loading_wrap_body}>
                <span className={styles.loading_body_span}>{text}</span>
                <HandySvg  src={loadingPopUp} className={`${styles.loading_body_svg} ${styles.rotate_animation}`}/>
            </div>
        </section>
        )
}

