export const SortingAccounts = (AllAccounts, Value) => {
    if (!Value) {
        return AllAccounts;
    }

    return AllAccounts.filter(account =>
        account.steamid.toString().includes(Value) ||
        account.personaname.includes(Value)
    );
}
