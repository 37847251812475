import {HandySvg} from 'handy-svg';
import styles from "../../../styles/components/ItemGrid.module.scss"
import success_added from "../../../assets/svg/success_added.svg"
import over_basket from "../../../assets/svg/add_to_basket.svg"
import {useState} from "react";
import {StoreBasket, StoreCurrency, StoreLoadInventory} from "../../../store/store";
import {GetCodeCurrency} from "../../../utils/inventory";

export const ItemGrid = (props) => {
    const ActiveSource = StoreLoadInventory((state) => state.ActiveSource)
    const [isOver, setIsOver] = useState(false);
    const BasketItems = StoreBasket((state) => state.Items)
    const AddBasketItem = StoreBasket((state) => state.AddItem)
    const RemoveItem = StoreBasket((state) => state.RemoveItem)
    const IsAdded = Object.values(BasketItems).filter(item => item["full id"] === props.item["full id"]).length
    return (
        <div onMouseOver={() => setIsOver(true)} onMouseLeave={() => setIsOver(false)} onClick={() => {
            if (props.item.marketable && props.item.tradable && props.item[ActiveSource]?.prices?.median > 0.2 && (ActiveSource === "Skin Sorter" || ActiveSource === "Steam")){
                if (IsAdded){
                 RemoveItem(props.item)
                }
                else {
                 AddBasketItem(props.item)
                }
            }
        }}
             className={styles.card_wrap}>
            {props.item.marketable && props.item.tradable && props.item[ActiveSource]?.prices?.median > 0.2 && (ActiveSource === "Skin Sorter" || ActiveSource === "Steam") ?
                <>
                    {!IsAdded && !isOver ? <StaticItemGrid item={props.item}/> : null}
                    {isOver && !IsAdded ? <OverItemGrid item={props.item} /> : null}
                    {IsAdded ? <AddedItemGrid item={props.item} /> : null}
                </>
            : <StaticItemGrid item={props.item}/>}
        </div>
    );
}

export const StaticItemGrid = (props) => {
    const ActiveSource = StoreLoadInventory((state) => state.ActiveSource)
    const ActiveCurrency = StoreLoadInventory((state) => state.Currency)
    const AllCurrency = StoreCurrency((state) => state.Currency)
    const price = ((props.item[ActiveSource]?.prices?.median || 0) * AllCurrency[ActiveCurrency]["name_usd"]).toFixed(2)
    const countPrice = (price * props.item.count).toFixed(2)

    return (
        <div className={styles.solo_card_2Line}>
            <div className={styles.sub_solo_card_content_span_wrap_2}>
                <span className={styles.sub_solo_card_content_span_2}>х {props.item.count}</span>
            </div>
            <img src={props.item.img} className={styles.solo_card_img} alt={props.item.market_hash_name}/>
                <div className={styles.solo_card_content}>
                    <span className={styles.solo_card_content_span} onClick={() => navigator.clipboard.writeText(props.item.full_market_hash_name)}>{props.item.market_hash_name}</span>
                    <div className={styles.sub_solo_card_content}>
                        <span className={styles.sub_solo_card_content_span_1} onClick={() => navigator.clipboard.writeText(GetCodeCurrency[ActiveCurrency] + " " + price )}>{GetCodeCurrency[ActiveCurrency]} {price}</span>
                        {props.item.count > 1 ? <span className={styles.sub_solo_card_content_span_1} onClick={() => navigator.clipboard.writeText(GetCodeCurrency[ActiveCurrency] + " " + price )}>{GetCodeCurrency[ActiveCurrency]} {countPrice}</span> : null}
                    </div>
                </div>
        </div>
    );
}
export const OverItemGrid = (props) => {
    const ActiveCurrency = StoreLoadInventory((state) => state.Currency)
    const AllCurrency = StoreCurrency((state) => state.Currency)
    return (
        <div className={styles.solo_card_2_desktop}>
            <div className={styles.solo_card_2}>
                <img src={props.item.img} className={styles.solo_card_img} alt={props.item.market_hash_name}/>
                    <div className={styles.solo_card_content}>
                        <span className={styles.solo_card_content_span}>{props.item.market_hash_name}</span>
                        <div className={styles.sub_solo_card_content}>
                            <span className={styles.sub_solo_card_content_span_1}>{GetCodeCurrency[ActiveCurrency]} {((props.item["Skin Sorter"]?.prices?.median || 0) * AllCurrency[ActiveCurrency]["name_usd"]).toFixed(2)}</span>
                        </div>
                    </div>
            </div>
            <div className={styles.solo_card_2_added}>
                <HandySvg src={over_basket} className={styles.solo_card_2_added_svg}/>
                <span className={styles.solo_card_2_added_span}>Продать</span>
            </div>
        </div>
    );
}


export const AddedItemGrid = (props) => {
    const ActiveCurrency = StoreLoadInventory((state) => state.Currency)
    const AllCurrency = StoreCurrency((state) => state.Currency)
    return (
        <div className={styles.solo_card_2_desktop}>
            <div className={styles.solo_card_2}>
                <img src={props.item.img} className={styles.solo_card_img} alt={props.item.market_hash_name}/>
                    <div className={styles.solo_card_content}>
                        <span className={styles.solo_card_content_span}>{props.item.market_hash_name}</span>
                        <div className={styles.sub_solo_card_content}>
                            <span className={styles.sub_solo_card_content_span_1}>{GetCodeCurrency[ActiveCurrency]} {((props.item["Skin Sorter"]?.prices?.median || 0) * AllCurrency[ActiveCurrency]["name_usd"]).toFixed(2)}</span>
                        </div>
                    </div>
            </div>
            <div className={styles.solo_card_2Line_add}>
                <HandySvg src={success_added} className={styles.solo_card_2Line_add_svg}/>
                <span className={styles.solo_card_2Line_add_span}>Добавлен</span>
            </div>
        </div>
    );
}

