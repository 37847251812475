import {HandySvg} from 'handy-svg';

import styles from "../../../styles/components/UserInfo.module.scss"

import cancel_cubic from "../../../assets/svg/cancel_no_rounded.svg"
import pencil from "../../../assets/svg/pencil.svg"
import blue_right_arrow from "../../../assets/svg/blue_right_arrow.svg"
import arrow_down from "../../../assets/svg/open_select_user.svg"
import {SortingAccounts} from "./SortingAccounts";
import {SelectRemoveCard} from "./SelectRemoveCard";
import {useState} from "react";
import {StoreUser} from "../../../store/store";
import {SaveUser} from "../../../services/api";
import {
    ValidateEmail,
    ValidateQiwi,
    ValidateTelegram,
    ValidateTradeLink,
    ValidateTRC20
} from "../../../utils/personalData";
import {AddCard, BASIC_URL_API} from "../../../services/api_constants";

export const UserInfo = () => {
    const Telegram = StoreUser((state) => state.Telegram)
    const SetTelegram = StoreUser((state) => state.SetTelegram)
    const Email = StoreUser((state) => state.Email)
    const SetEmail = StoreUser((state) => state.SetEmail)
    const Qiwi = StoreUser((state) => state.Qiwi)
    const SetQiwi = StoreUser((state) => state.SetQiwi)
    const Trc20 = StoreUser((state) => state.Trc20)
    const SetTrc20 = StoreUser((state) => state.SetTrc20)
    const LastActiveBankCard = StoreUser((state) => state.LastActiveBankCard)
    const BankCard = StoreUser((state) => state.BankCard)
    const SetLastActiveBankCard = StoreUser((state) => state.SetLastActiveBankCard)
    const SetSteamData = StoreUser((state) => state.SetSteamData)
    const TradeLink = StoreUser((state) => state.TradeLink)
    const SetTradeLink = StoreUser((state) => state.SetTradeLink)
    const [IsOpenBankList, SetIsOpenBankList] = useState(false)

    const [IsEditTradeLink, SetIsEditTradeLink] = useState(false)
    const [IsEditTelegram, SetIsEditTelegram] = useState(false)
    const [IsEditEmail, SetIsEditEmail] = useState(false)
    const [IsEditQiwi, SetIsEditQiwi] = useState(false)
    const [IsEditTRC20, SetIsEditTRC20] = useState(false)

    const [IsErrorTradeLink, SetIsErrorTradeLink] = useState(false)
    const [IsErrorTelegram, SetIsErrorTelegram] = useState(false)
    const [IsErrorEmail, SetIsErrorEmail] = useState(false)
    const [IsErrorQiwi, SetIsErrorQiwi] = useState(false)
    const [IsErrorTRC20, SetIsErrorTRC20] = useState(false)

    const Submit = async (event, f) => {
        if (event.key === 'Enter' && document.activeElement === event.target) {
            if (
                ValidateTradeLink(TradeLink) && ValidateTelegram(Telegram) &&
                ValidateEmail(Email) && ValidateQiwi(Qiwi) && ValidateTRC20(Trc20)
            ) {
                f(false)
                await SaveUser(Telegram, Email, BankCard, Qiwi, Trc20, TradeLink)
            }
            else{
                alert("Ошибка валидации данных")
            }
        }
    };


    return (
        <section className={styles.for_bg_CS}>
            <div>
                <div className={styles.sub_general_information}>
                    <h2 className={styles.sub_general_information_h2}>Общая информация основного аккаунта</h2>
                </div>
                <div className={styles.general_information_block}>
                    <div className={styles.sub_general_information_block_frst}>
                        <div className={styles.sub_general_information_block_span_frst_block}>
                            <span className={styles.sub_general_information_block_span_frst}>Trade link:</span>
                        </div>
                        <div className={styles.input_general_information_block}>
                            <input className={IsErrorTradeLink ? styles.error_promo : styles.input_promo} value={TradeLink} onKeyDown={(event) => Submit(event, SetIsEditTradeLink)} onChange={(e) => {
                                SetTradeLink(e.target.value)
                                SetIsEditTradeLink(true)
                            }}
                                   type="text" name="" id="" placeholder="ttps://steamcommunity.com/profiles/765611988..."/>
                            {IsEditTradeLink ? null : <HandySvg src={cancel_cubic} onClick={() => {
                                SetSteamData("", 'trade link')
                                SetIsEditTradeLink(true)
                            }} className={styles.input_general_information_block_svg}/>}
                        </div>
                        <div className={styles.sub_general_information_block_last}>
                            <span className={styles.sub_general_information_block_span}>Получить ссылку</span>
                            {IsEditTradeLink ? <span className={styles.sub_general_information_block_span} onClick={() => {
                                if (ValidateTradeLink(TradeLink)){
                                    SaveUser(Telegram, Email, BankCard, Qiwi, Trc20, TradeLink)
                                    SetIsEditTradeLink(false)
                                    SetIsErrorTradeLink(false)
                                }
                                else {
                                    SetIsErrorTradeLink(true)
                                }
                            }}>Применить</span> : null}
                        </div>
                    </div>
                    <div className={styles.sub_general_information_block}>
                        <div className={styles.sub_general_information_block_span_frst_block}>
                            <span className={styles.sub_general_information_block_span_scnd}>Telegram:</span>
                        </div>
                        <div className={styles.input_general_information_block}>
                            <input className={IsErrorTelegram ? styles.error_promo : styles.input_promo} value={Telegram} onKeyDown={(event) => Submit(event, SetIsEditTelegram)} onChange={(e) => {
                                SetTelegram(e.target.value)
                                SetIsEditTelegram(true)
                            }}
                                   placeholder="-" type="text" name="" id=""/>
                            {IsEditTelegram ? null : <HandySvg src={pencil} className={styles.input_general_information_block_svg} onClick={() => SetIsEditTelegram(true)}/>}
                        </div>
                        <div className={styles.sub_general_information_block_last}>
                            {IsEditTelegram ? <span className={styles.sub_general_information_block_span} onClick={() => {
                                if (ValidateTelegram(Telegram)){
                                    SaveUser(Telegram, Email, BankCard, Qiwi, Trc20, TradeLink)
                                    SetIsEditTelegram(false)
                                    SetIsErrorTelegram(false)
                                }
                                else {
                                    SetIsErrorTelegram(true)
                                }
                            }}>Привязать</span> : null}
                        </div>
                    </div>
                    <div className={styles.sub_general_information_block}>
                        <div className={styles.sub_general_information_block_span_frst_block}>
                            <span className={styles.sub_general_information_block_span_thrd}>E-mail:</span>
                        </div>
                        <div className={styles.input_general_information_block}>
                            <input className={IsErrorEmail ? styles.error_promo : styles.input_promo} value={Email} onKeyDown={(event) => Submit(event, SetIsEditEmail)} onChange={(e) => {
                                SetEmail(e.target.value)
                                SetIsEditEmail(true)
                            }}
                                   placeholder="email@mail.ru" type="text" name="" id=""/>
                            {IsEditEmail ? null : <HandySvg src={pencil} className={styles.input_general_information_block_svg} onClick={() => SetIsEditEmail(true)}/>}
                        </div>
                        <div className={styles.sub_general_information_block_last}>
                            {IsEditEmail ? <span className={styles.sub_general_information_block_span} onClick={() => {
                                if (ValidateEmail(Email)){
                                    SaveUser(Telegram, Email, BankCard, Qiwi, Trc20, TradeLink)
                                    SetIsEditEmail(false)
                                    SetIsErrorEmail(false)
                                }
                                else {
                                    SetIsErrorEmail(true)
                                }
                            }}>Привязать</span> : null}
                        </div>
                    </div>
                    <div className={styles.general_information_props}>
                        <h2 className={styles.general_information_props_h2}>Платежные реквизиты</h2>
                    </div>

                    <div className={styles.general_information_requisites}>
                        <div className={styles.sub_general_information_requisites_frst}>
                            <div className={styles.sub_general_information_block_span_frst_block}>
                                <span className={styles.sub_general_information_block_span_BankCard}>Банковская карта:</span>
                            </div>
                            <div className="m-auto">
                                <div className={styles.input_general_information_block}>
                                    <input readOnly={true} className={styles.input_promo} onClick={() => SetIsOpenBankList(!IsOpenBankList)}
                                           value={LastActiveBankCard} onChange={(e) => SetLastActiveBankCard(e.target.value)}
                                           placeholder="**** **** **** **99" type="text" name="" id=""/>
                                    {IsOpenBankList ? <HandySvg onClick={() => SetIsOpenBankList(false)}  src={arrow_down} className={styles.input_general_information_block_svg}/> :
                                        <HandySvg  onClick={() => SetIsOpenBankList(true)} src={cancel_cubic} className={styles.input_general_information_block_svg}/>
                                    }
                                </div>
                                {IsOpenBankList ? <SelectRemoveCard/> : null}
                            </div>
                            <div className={styles.add_bank_card} >
                                <a href={BASIC_URL_API + "/" + AddCard} className={styles.add_bank_card_a}>ДОБАВИТЬ БАНКОВСКУЮ КАРТУ</a>
                                <HandySvg src={blue_right_arrow} className={styles.add_bank_card_svg}/>
                            </div>


                        </div>
                        <div className={styles.sub_general_information_requisites}>
                            <div className={styles.sub_general_information_block_span_frst_block}>
                                <span className={styles.sub_general_information_block_span_qiwi}>QIWI кошелек:</span>
                            </div>
                            <div className={styles.input_general_information_block}>
                                <input className={IsErrorQiwi ? styles.error_promo : styles.input_promo}
                                       value={Qiwi} onKeyDown={(event) => Submit(event, SetIsEditQiwi)} onChange={(e) => {
                                    SetQiwi(e.target.value)
                                    SetIsEditQiwi(true)
                                }}
                                       placeholder="59595****95959" type="text" name="" id=""/>
                                {IsEditQiwi ? null : <HandySvg src={pencil} className={styles.input_general_information_block_svg} onClick={() => SetIsEditQiwi(true)}/>}
                            </div>
                            <div className={styles.sub_general_information_block_last}>
                                {IsEditQiwi ? <span className={styles.sub_general_information_block_span} onClick={() => {
                                    if (ValidateQiwi(Qiwi)) {
                                        SaveUser(Telegram, Email, BankCard, Qiwi, Trc20, TradeLink)
                                        SetIsEditQiwi(false)
                                        SetIsErrorQiwi(false)
                                    }
                                    else{
                                        SetIsErrorQiwi(true)
                                    }

                                }}>Сохранить</span> : null
                                }
                            </div>
                        </div>
                        <div className={styles.sub_general_information_requisites}>
                            <div className={styles.sub_general_information_block_span_frst_block}>
                                <span className={styles.sub_general_information_block_span_trc20}>TRC- 20 кошелек:</span>
                            </div>
                            <div className={styles.input_general_information_block}>
                                <input className={IsErrorTRC20 ? styles.error_promo : styles.input_promo} onKeyDown={(event) => Submit(event, SetIsEditTRC20)} onChange={(e) => {
                                    SetTrc20(e.target.value)
                                    SetIsEditTRC20(true)
                                }}
                                       value={Trc20}
                                       placeholder="59595959595959" type="text" name="" id=""/>
                                {IsEditTRC20 ? null : <HandySvg src={pencil} className={styles.input_general_information_block_svg} onClick={() => SetIsEditTRC20(true)}/>}
                            </div>
                            <div className={styles.sub_general_information_block_last}>
                                {IsEditTRC20 ? <span className={styles.sub_general_information_block_span} onClick={() => {
                                    if (ValidateTRC20(Trc20)) {
                                        SaveUser(Telegram, Email, BankCard, Qiwi, Trc20, TradeLink)
                                        SetIsEditTRC20(false)
                                        SetIsErrorTRC20(false)
                                    }
                                    else{
                                        SetIsErrorTRC20(true)
                                    }
                                }}>Сохранить</span> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SortingAccounts/>
        </section>
    )
}