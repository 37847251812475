import styles from "../../../styles/components/ItemGrid.module.scss"
import {ItemGrid} from "./ItemGrid";
import {StoreLoadInventory} from "../../../store/store";
import {FilterInventory, GroupingItems, ReplaceNames, Scroll, SortingItems} from "../../../utils/inventory";
export const ItemsGrid = () => {
    const ActiveSource = StoreLoadInventory((state) => state.ActiveSource)
    const Items = StoreLoadInventory((state) => state.Items)
    const GridScrollStart = StoreLoadInventory((state) => state.GridScrollStart)
    const GridStep = StoreLoadInventory((state) => state.GridStep)
    const Tradable = StoreLoadInventory((state) => state.Tradable)
    const Marketrable = StoreLoadInventory((state) => state.Marketrable)
    const SortingQuantity = StoreLoadInventory((state) => state.SortingQuantity)
    const SortingPrice = StoreLoadInventory((state) => state.SortingPrice)
    let items = FilterInventory(Items, Tradable, Marketrable)
    items = GroupingItems(items)
    items = SortingItems(items, SortingQuantity, SortingPrice, ActiveSource)
    items = ReplaceNames(items)
    items = Scroll(items, GridScrollStart, GridStep)
    return (
        <section>
            <div className={styles.card}>
                <div className={styles.sub_card}>
                    {items.map((item, number) => <ItemGrid item={item} number={number}/>)}
                </div>
            </div>
        </section>
    );
}