import styles from "../../../styles/components/TotalGoods.module.scss";
import {StoreBasket} from "../../../store/store";

export const TotalGoods = () => {
    const Items = StoreBasket((state) => state.Items)
    return (
        <div className={styles.total_goods}>
            <span className={styles.total_goods_span_1}>Всего товаров:</span>
            <span className={styles.total_goods_span_2}>{Items.length}</span>
        </div>
    )
}