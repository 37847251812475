import styles from "../../../styles/components/ItemsRows.module.scss"
import {StoreBasket, StoreCurrency, StoreLoadInventory} from "../../../store/store";
import {GetCodeCurrency} from "../../../utils/inventory";
import classNames from "classnames";
export const ItemRows = (props) => {
    const ActiveSource = StoreLoadInventory((state) => state.ActiveSource)
    const ActiveCurrency = StoreLoadInventory((state) => state.Currency)
    const AllCurrency = StoreCurrency((state) => state.Currency)
    const BasketItems = StoreBasket((state) => state.Items)
    const RemoveItem = StoreBasket((state) => state.RemoveItem)
    const AddBasketItem = StoreBasket((state) => state.AddItem)
    const IsAdded = Object.values(BasketItems).filter(item => item["full id"] === props.item["full id"]).length
    return (
        <div className={styles.card}>
            <div className={styles.sub_card}>
                <div className={styles.bg_steel}>
                    <img src={props.item.img} className={styles.bg_steel_img} alt="negev"/>
                </div>
                <div className={styles.content_card}>
                    <span className={styles.prgrf_card}>{props.item.market_hash_name}</span>
                    <span className={styles.content_card_span_1}>x{props.item.count}</span>
                    <span className={styles.content_card_span_2}>{((props.item[ActiveSource]?.prices?.median || 0) * AllCurrency[ActiveCurrency]["name_usd"]).toFixed(2)} {GetCodeCurrency[ActiveCurrency]}</span>
                    {props.item.marketable && props.item.tradable && ActiveSource === "Skin Sorter" ?
                        <span onClick={() => IsAdded ? RemoveItem(props.item) : AddBasketItem(props.item)} className={classNames(styles.B_link_card_1, IsAdded ? styles.b_link_added_text : styles.b_link_hover_text)}>{IsAdded && props.item.tradable && props.item.marketable ? "ДОБАВЛЕН" : "Добавить в корзину"}</span>
                        : null
                    }
                </div>
            </div>
        </div>
    );
}