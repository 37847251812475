import styles from "../styles/components/Main.module.scss";
import {Banner} from "../components/common/main/Banner";
import {Description} from "../components/common/main/Description";
import {GameBlock} from "../components/modules/GameBlock";
import {Filters} from "../components/common/main/Filters";
import {SubUser} from "../components/common/main/SubUser";
import {FilterBasket} from "../components/common/main/FilterBasket";
import {ItemsGrid} from "../components/common/main/ItemsGrid";
import {ScrollPage} from "../components/common/main/ScrollPage";
import {ItemsRows} from "../components/common/main/ItemsRows";
import {SortingRows} from "../components/common/main/SortingRows";
import {AuthPopUp} from "../components/common/main/AuthPopUp";
import {ErrorLoadingPopUp} from "../components/common/main/ErrorLoadingPopUp";
import {StoreLoadInventory} from "../store/store";

export const Main = () => {
    const Items = StoreLoadInventory((state) => state.Items);
    const GridRow = StoreLoadInventory((state) => state.GridRow)
    return (
        <main>
            <AuthPopUp/>
            <ErrorLoadingPopUp/>
            <section className={styles.top_cs_wrap}>
                <Banner/>
                <Description/>
                <GameBlock/>
                <div className={styles.add_block}></div>
            </section>
                <SubUser/>
                <Filters/>
                {GridRow === "Grid" ?
                    <>
                        <FilterBasket/>
                        <ItemsGrid/>
                        {Items.length ? <ScrollPage/> : null}
                    </>
                    :
                    <>
                        <SortingRows/>
                        <ItemsRows/>
                    </>
                }
        </main>
    );
}