import {CloseTradeAPI, CreateTradeAPI, StatusTrade} from "../services/api";

export const CreateTrade = async (
                                PaymentType,
                                Qiwi,
                                Trc20,
                                Email,
                                Items,
                                FullPrice,
                                FinishPrice,
                                CommissionPrice,
                                TradeLink,
                                LastActiveBankCard
                            ) => {
    return await CreateTradeAPI({
        PaymentType,
        Qiwi,
        Trc20,
        Email,
        Items,
        FullPrice,
        FinishPrice,
        CommissionPrice,
        TradeLink,
        LastActiveBankCard
    });
};

export const GetStatusTrade = async (TradeId, CheckTrade=null) => {
    return await StatusTrade(TradeId, CheckTrade)
}

export const CloseTrade = async (TradeId) => {
    return await CloseTradeAPI(TradeId)
}


export const Sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}
export const getTradeOfferStateData = (status) => {
    switch (status) {
        case 1:
            return {
                "str status ru": "Инвалид",
                "ETradeOfferState": "k_ETradeOfferStateInvalid",
                "status": 1
            };
        case 2:
            return {
                "str status ru": "Обмен был отправлен, но не принят пользователем",
                "ETradeOfferState": "k_ETradeOfferStateActive",
                "status": 2
            };
        case 3:
            return {
                "str status ru": "Предложение обмена было принято получателем, и предметы были обменены.",
                "ETradeOfferState": "k_ETradeOfferStateAccepted",
                "status": 3
            };
        case 4:
            return {
                "str status ru": "Получатель сделал встречное предложение",
                "ETradeOfferState": "k_ETradeOfferStateCountered",
                "status": 4
            };
        case 5:
            return {
                "str status ru": "Торговое предложение не было принято до истечения срока действия",
                "ETradeOfferState": "k_ETradeOfferStateExpired",
                "status": 5
            };
        case 6:
            return {
                "str status ru": "Отправитель отменил предложение",
                "ETradeOfferState": "k_ETradeOfferStateCanceled",
                "status": 6
            };
        case 7:
            return {
                "str status ru": "Получатель отклонил предложение",
                "ETradeOfferState": "k_ETradeOfferStateDeclined",
                "status": 7
            };
        case 8:
            return {
                "str status ru": "Некоторые предметы в предложении больше не доступны (обозначаются отсутствующим флагом в выводе)",
                "ETradeOfferState": "k_ETradeOfferStateInvalidItems",
                "status": 8
            };
        case 9:
            return {
                "str status ru": "Предложение еще не отправлено и ожидает подтверждения по электронной почте или мобильному телефону. Предложение видно только отправителю.",
                "ETradeOfferState": "k_ETradeOfferStateCreatedNeedsConfirmation",
                "status": 9
            };
        case 10:
            return {
                "str status ru": "Любая из сторон отменила предложение по электронной почте или мобильному телефону. Предложение видно обеим сторонам, даже если отправитель отменил его до того, как оно было отправлено.",
                "ETradeOfferState": "k_ETradeOfferStateCanceledBySecondFactor",
                "status": 10
            };
        case 11:
            return {
                "str status ru": "Торговля приостановлена. Предметы, участвующие в обмене, были удалены из инвентарей обеих сторон и будут автоматически доставлены в будущем.",
                "ETradeOfferState": "k_ETradeOfferStateInEscrow",
                "status": 11
            };
        default:
            return null; // Если передано неподдерживаемое состояние
    }
}
