import {HandySvg} from 'handy-svg';
import styles from "../../../styles/components/AuthPopUp.module.scss"
import cancel_popup from "../../../assets/svg/cancel_banner.svg";
import {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {StoreAuthPopup} from "../../../store/store";
import {BASIC_URL, BASIC_URL_API} from "../../../services/api_constants";
export const  AuthPopUp = () => {
    const SetIsOpenAuth = StoreAuthPopup((state) => state.SetIsOpen)
    const IsOpenAuth = StoreAuthPopup((state) => state.IsOpen)
    return (
        <Transition appear show={IsOpenAuth} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => SetIsOpenAuth(false)}>
                <div className="fixed inset-0 overflow-y-auto bg-opacity-40 bg-black">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel>
                                <div className={styles.authorization}>
                                    <div className={styles.header_authorization}>
                                        <HandySvg onClick={() => SetIsOpenAuth(false)} src={cancel_popup} className={styles.cross_3}/>
                                        <h2 className={styles.prgrf_authorization}>Авторизация</h2>
                                    </div>
                                    <div>
                                       <span className={styles.authorization_text}>Авторизуйтесь через <span className="text-white">Steam</span>, чтобы
                                           открыть доступ ко всему функционалу сервиса! ;)</span>
                                        <a href={BASIC_URL_API + "login"} id="logo" className={styles.login_via_steam}>Войти через стим</a>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>

    );
}

