import styles from "../../../styles/components/SelectCurrency.module.scss"
import {StoreLoadInventory} from "../../../store/store";

export const SelectCurrency = (props) => {
    const SetCurrency = StoreLoadInventory((state) => state.SetCurrency)
    const Currency = StoreLoadInventory((state) => state.Currency)
    return (
        <div className={styles.content_block_currency} onMouseLeave={props.onMouseLeave}>
            <span onClick={() => SetCurrency("RUB")} className={Currency === "RUB" ? styles.rub_currency : styles.any_currency}>Рубль (₽)</span>
            <span onClick={() => SetCurrency("USD")} className={Currency === "USD" ? styles.rub_currency : styles.any_currency}>Доллар ($)</span>
            <span onClick={() => SetCurrency("EURO")} className={Currency === "EURO" ? styles.rub_currency : styles.any_currency}>Евро (€)</span>
            <span onClick={() => SetCurrency("YUAN")} className={Currency === "YUAN" ? styles.rub_currency : styles.any_currency}>Юань (¥)</span>
            <span onClick={() => SetCurrency("TENGE")} className={Currency === "TENGE" ? styles.rub_currency : styles.any_currency}>Тенге (₸)</span>
            <span onClick={() => SetCurrency("BEL RUB")} className={Currency === "BEL RUB" ? styles.rub_currency : styles.any_currency}>Бел. рубль (Br)</span>
        </div>
    )
}