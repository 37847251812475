import {HandySvg} from 'handy-svg';
import styles from "../../../styles/components/Filters.module.scss"
import sorting_down from "../../../assets/svg/sorting_down.svg";
import {useState} from "react";
import {StoreLoadInventory} from "../../../store/store";
import {FilterInventory, GetFullFilter} from "../../../utils/inventory";



export const OneOrManyFilter = () => {
    const AloneOrGroup = StoreLoadInventory((state) => state.AloneOrGroup)
    const SetAloneOrGroup = StoreLoadInventory((state) => state.SetAloneOrGroup)
    const [OpenFilter, SetOpenFilter] = useState(false)
    return (
        <div>
            <div className={styles.sub_filter_card} onClick={() => SetOpenFilter(!OpenFilter)}>
                <span className={styles.sub_filter_card_span}>{GetFullFilter[AloneOrGroup]}</span>
                <HandySvg src={sorting_down} className={styles.sub_filter_card_svg}/>
            </div>
            {OpenFilter ? <div className={styles.sub_fiters_two} onClick={() => SetOpenFilter(false)} onMouseLeave={() => SetOpenFilter(false)}>
                <span onClick={() => SetAloneOrGroup("Alone")} className={styles.frst_fiter_span_white}>Одиночный</span><br/>
                <span onClick={() => SetAloneOrGroup("Group")} className={styles.two_fiter_span}>Групповой</span>
            </div> : null}
        </div>
    )
}

export const SourceFilter = () => {
    const ActiveSource = StoreLoadInventory((state) => state.ActiveSource)
    const SetActiveSource = StoreLoadInventory((state) => state.SetActiveSource)
    const [OpenFilter, SetOpenFilter] = useState(false)
    return (
        <div>
            <div className={styles.sub_filter_card} onClick={() => SetOpenFilter(!OpenFilter)}>
                <span className={styles.sub_filter_card_span}>{ActiveSource}</span>
                <HandySvg src={sorting_down} className={styles.sub_filter_card_svg}/>
            </div>
            {OpenFilter ? <div className={styles.sub_fiters_four} onClick={() => SetOpenFilter(false)} onMouseLeave={() => SetOpenFilter(false)}>
                <span onClick={() => SetActiveSource("Steam")} className={ActiveSource === "Steam" ? styles.frst_fiter_span_white : styles.frst_fiter_span}>Steam</span><br/>
                <span onClick={() => SetActiveSource("Buff.163")} className={ActiveSource === "Buff.163" ? styles.two_fiter_span_white : styles.two_fiter_span}>Buff.163</span><br/>
                <span onClick={() => SetActiveSource("TM Market")} className={ActiveSource === "TM Market" ? styles.two_fiter_span_white : styles.two_fiter_span}>TM Market</span>
                <span onClick={() => SetActiveSource("Skin Sorter")} className={ActiveSource === "Skin Sorter" ? styles.three_fiter_span_white : styles.three_fiter_span}>Skin Sorter</span>
            </div> : null}
        </div>
    )
}

export const GridFilter = () => {
    const GridRow = StoreLoadInventory((state) => state.GridRow)
    const SetGridRow = StoreLoadInventory((state) => state.SetGridRow)
    const [OpenFilter, SetOpenFilter] = useState(false)
    return (
        <div>
            <div className={styles.sub_filter_card} onClick={() => SetOpenFilter(!OpenFilter)}>
                <span className={styles.sub_filter_card_span}>{GetFullFilter[GridRow]}</span>
                <HandySvg src={sorting_down} className={styles.sub_filter_card_svg}/>
            </div>
            {OpenFilter ? <div className={styles.sub_fiters_two} onClick={() => SetOpenFilter(false)} onMouseLeave={() => SetOpenFilter(false)}>
                <span onClick={() => SetGridRow("Grid")} className={GridRow === "Grid" ? styles.frst_fiter_span_white : styles.frst_fiter_span}>Сетка</span><br/>
                <span onClick={() => SetGridRow("Rows")} className={GridRow === "Rows" ? styles.two_fiter_span_white : styles.two_fiter_span}>Таблица</span>
            </div> : null}
        </div>

    )
}

export const TradableFilter = () => {
    const Tradable = StoreLoadInventory((state) => state.Tradable)
    const SetTradable = StoreLoadInventory((state) => state.SetTradable)
    const [OpenFilter, SetOpenFilter] = useState(false)
    return (
        <div>
            <div className={styles.sub_filter_card} onClick={() => SetOpenFilter(!OpenFilter)}>
                <span className={styles.sub_filter_card_span}>{GetFullFilter[Tradable]}</span>
                <HandySvg src={sorting_down} className={styles.sub_filter_card_svg}/>
            </div>
            {OpenFilter ? <div className={styles.sub_fiters} onClick={() => SetOpenFilter(false)} onMouseLeave={() => SetOpenFilter(false)}>
                <span onClick={() => SetTradable("All")} className={Tradable === "All" ? styles.frst_fiter_span_white : styles.frst_fiter_span}>Все</span><br/>
                <span onClick={() => SetTradable("Tradable")} className={Tradable === "Tradable" ? styles.two_fiter_span_white : styles.two_fiter_span}>Tradable</span><br/>
                <span onClick={() => SetTradable("UnTradable")} className={Tradable === "UnTradable" ? styles.two_fiter_span_white : styles.two_fiter_span}>UnTradable</span>
            </div> : null}
        </div>

    )
}
export const MarketrableFilter = () => {
    const Marketrable = StoreLoadInventory((state) => state.Marketrable)
    const SetMarketrable = StoreLoadInventory((state) => state.SetMarketrable)
    const [OpenFilter, SetOpenFilter] = useState(false)
    return (
        <div>
            <div className={styles.sub_filter_card} onClick={() => SetOpenFilter(!OpenFilter)}>
                <span className={styles.sub_filter_card_span}>{GetFullFilter[Marketrable]}</span>
                <HandySvg src={sorting_down} className={styles.sub_filter_card_svg}/>
            </div>
            {OpenFilter ? <div className={styles.sub_fiters} onClick={() => SetOpenFilter(false)} onMouseLeave={() => SetOpenFilter(false)}>
                <span onClick={() => SetMarketrable("All")} className={Marketrable === "All" ? styles.frst_fiter_span_white : styles.frst_fiter_span}>Все</span><br/>
                <span onClick={() => SetMarketrable("Marketrable")} className={Marketrable === "Marketrable" ? styles.two_fiter_span_white : styles.two_fiter_span}>Marketrable</span><br/>
                <span onClick={() => SetMarketrable("UnMarketrable")} className={Marketrable === "UnMarketrable" ? styles.two_fiter_span_white : styles.two_fiter_span}>UnMarketrable</span>
            </div> : null}
        </div>
    )
}

export const Filters = () => {
    const Items = StoreLoadInventory((state) => state.Items)
    const Game = StoreLoadInventory((state) => state.Game)
    return (
        <section>
            <div className={styles.filter}>
                <div className={styles.subend_filter_basket}>
                    <span className={styles.subend_filter_basket_span_1}>Всего в инвентаре по {Game} найдено <span
                        className={styles.subend_filter_basket_span_2}>{Items.length}</span> скинов ( <span
                        className={styles.subend_filter_basket_span_2}>{FilterInventory(Items, "All", "Marketrable").length}</span> платные):</span>
                </div>
                <div className={styles.desktop_filter_wrap}>
                    <div className={styles.desktop_filter}>
                        <div className={styles.sub_filter}>
                            <SourceFilter/>
                            <TradableFilter/>
                        </div>
                        <div className={styles.sub_filter}>
                            <MarketrableFilter/>
                            <GridFilter/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}