import {HandySvg} from 'handy-svg';
import cancel_banner from '../../../assets/svg/cancel_banner.svg';
import styles from "../../../styles/components/Banner.module.scss"
import {useEffect, useState} from "react";

export const Banner = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => setCurrentImageIndex(currentImageIndex === 0 ? 1 : 0), 5000);

        return () => clearInterval(interval);
    }, [currentImageIndex]);

    return (
        <section className={styles.headband_wrap}>
            {/*<a href={currentImageIndex === 0 ? "https://tradeback.io/r/tf2key" : "https://tf2key.ru/"} target="_blank" rel="noreferrer">*/}
            {/* <a href="https://steamcommunity.com/tradeoffer/new/?partner=1215446624&token=gD5qdUPA" target="_blank" rel="noreferrer"></a> */}
            <a href="https://market.csgo.com/?utm_source=Steaminventory&utm_medium=banner&utm_campaign=site_pc" target="_blank" rel="noreferrer">
                <div className={styles.headband_support}>
                    {/* <HandySvg src={cancel_banner} className={styles.headband_svg}/> */}
                </div>
            </a>
        </section>
    );
}
