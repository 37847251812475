import styles from "../../../styles/components/AccountsRows.module.scss"
import {AccountRow} from "./AccountRow";
import {StoreUser} from "../../../store/store";
import {SortingAccounts} from "../../../utils/Sorting";


export const AccountsRows = () => {
    const Accounts = StoreUser((state) => state.Accounts)
    const FiltersAccounts = StoreUser((state) => state.FiltersAccounts)
    return (
        <section className={styles.sect_card}>
            <div className={styles.all_card}>
                {Accounts ? SortingAccounts(Accounts, FiltersAccounts).map((item, number) =>
                    <AccountRow item={item} key={number}/>
                ) : null}
            </div>
        </section>
    )
}