import {HandySvg} from 'handy-svg';
import styles from "../../../styles/components/ErrorLoadingPopUp.module.scss"
import cancel_popup from "../../../assets/svg/cancel_popup.svg"
import error from "../../../assets/svg/error.svg"
import {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {StoreLoadInventory} from "../../../store/store";

export const ErrorLoadingPopUp = () => {
    const ErrorLoadingPopUp = StoreLoadInventory((state) => state.ErrorLoadingPopUp)
    const SetErrorLoadingPopUp = StoreLoadInventory((state) => state.SetErrorLoadingPopUp)
    const ErrorText = StoreLoadInventory((state) => state.ErrorText)
    return (
        <Transition appear show={ErrorLoadingPopUp} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => SetErrorLoadingPopUp(false)}>
                <div className="fixed inset-0 overflow-y-auto bg-opacity-40 bg-black">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel>
                                <div className={styles.error_loading}>
                                    <div>
                                        <HandySvg src={cancel_popup} className={styles.cross_3} onClick={() => SetErrorLoadingPopUp(false)}/>
                                        <HandySvg src={error} className={styles.error_svg}/>
                                        <h2 className={styles.error_loading_prgrf}>Ошибка загрузки предметов</h2>
                                        <span className={styles.error_loading_text}>{ErrorText ? ErrorText : "Приносим свои извинения! Попробуйте снова через некоторое время!"}</span>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}