import styles from "../../../styles/components/SubUser.module.scss"
import LoadingIMG from '../../../assets/img/LoadingIMG.png';
import {StoreCurrency, StoreLoadInventory} from "../../../store/store";
import {
    FilterInventory,
    GetAppIdByName,
    GetCodeCurrency,
    SumInventory
} from "../../../utils/inventory";
import {BASIC_URL_API, ReportAccountLink, ReportAccountMainLink} from "../../../services/api_constants";
export const SubUser = () => {
    const ActiveSource = StoreLoadInventory((state) => state.ActiveSource)
    const UserName = StoreLoadInventory((state) => state.UserName)
    const InventoryId = StoreLoadInventory((state) => state.InventoryId)
    const Items = StoreLoadInventory((state) => state.Items)
    const UserImage = StoreLoadInventory((state) => state.UserImage)
    const ActiveGame = StoreLoadInventory((state) => state.Game)
    const Currency = StoreLoadInventory((state) => state.Currency)
    const ActiveCurrency = StoreLoadInventory((state) => state.Currency)
    const AllCurrency = StoreCurrency((state) => state.Currency)
    const Tradable = StoreLoadInventory((state) => state.Tradable)
    const Marketrable = StoreLoadInventory((state) => state.Marketrable)
    return (
        <section>
            <div className={styles.user}>
                <div className={styles.sub_user}>
                    <img src={UserImage ? UserImage : LoadingIMG} className={styles.sub_user_img} alt="Man"/>
                        <div className={styles.sub_user_card}>
                            <h2 className={styles.sub_user_card_h2}>{UserName}</h2>
                            <span className={styles.sub_user_card_span}>
                                Стоимость инвентаря по <span className={styles.white_semibold_text}>{ActiveGame} :</span>
                            </span>
                            <br/>
                            <span className={styles.sub_user_card_span_price}>{(SumInventory(FilterInventory(Items, Tradable, Marketrable), ActiveSource) * AllCurrency[ActiveCurrency]["name_usd"]).toFixed(2)} {GetCodeCurrency[Currency]} </span>
                        </div>

                </div>

                <div className={styles.sub_user_price}>
                    <span className={styles.sub_user_price_span}>Скачать стоимость инвентаря в <span
                        className={styles.white_text}>txt формате:</span></span>
                    <div className={styles.download}>
                        <a
                            href={InventoryId ? `${BASIC_URL_API}${ReportAccountMainLink}?steamid=${InventoryId}&app_id=${GetAppIdByName[ActiveGame]}` : null}
                            download={`${UserName}___${GetAppIdByName[ActiveGame]}.xlsx`}
                            id="logo" className={styles.download_button}>
                            Скачать
                        </a>
                    </div>
                </div>

            </div>
        </section>
    );
}