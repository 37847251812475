import styles from "../../../styles/components/SuccessOperation.module.scss"
import success from "../../../assets/svg/success_added.svg"
import {HandySvg} from 'handy-svg';
import {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {StoreBasket} from "../../../store/store";
import error from "../../../assets/svg/error.svg";
import {CloseTrade, getTradeOfferStateData} from "../../../utils/Trade";
import quit from "../../../assets/svg/cancel_banner.svg";


export const SuccessOperation = () => {
    const IsOpen = StoreBasket((state) => state.IsOpenSuccess)
    const SetIsOpen = StoreBasket((state) => state.SetIsOpenSuccess)
    const TradeId = StoreBasket((state) => state.TradeId)
    const TradeIdInner = StoreBasket((state) => state.TradeIdInner)
    const Status = StoreBasket((state) => state.Status)
    const SetStatus = StoreBasket((state) => state.SetStatus)

    const GetStatus = () => {
        if (Status["status"] === 3){
            return "Обмен принят!"
        }
        else if ([6, 7, 8, 10].includes(Status["status"])){
            return "Обмен отклонен!"
        }
        else {
            return "Трейд создан!"
        }
    }
    const closePopUp = async () => {
        SetIsOpen(false)
        await CloseTrade(TradeIdInner)
        SetStatus(getTradeOfferStateData(2))
    }
    return (
        <Transition appear show={IsOpen} as={Fragment} onClose={() => null}>
            <Dialog as="div" className="relative z-20">
                <div className="fixed inset-0 overflow-y-auto bg-opacity-40 bg-black">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel>
                                <div className={styles.success_loading}>
                                    <div>
                                        <div onClick={closePopUp}>
                                            <HandySvg src={quit} className={styles.cross_3}/>
                                        </div>
                                        {[6, 7, 8, 10].includes(Status["status"]) ? <HandySvg src={error} className={styles.error_svg}/> : <HandySvg src={success} className={styles.success_svg}/>}
                                        <h2 className={styles.success_loading_prgrf}>{GetStatus()}</h2>
                                        {[6, 7, 8, 10].includes(Status["status"]) ? null : <span className={styles.success_loading_text}>После подтверждения обмена деньги будут отправлены автоматически.</span>}
                                        <span className={[6, 7, 8, 10].includes(Status["status"]) ? styles.status_trade_text_block : styles.status_trade_text}>{Status["str status ru"]}</span>
                                        {[6, 7, 8, 10, 3].includes(Status["status"]) ? null :
                                            <div className={styles.open_trade_button}>
                                                <a href={`https://steamcommunity.com/tradeoffer/${TradeId}/`} target="_blank" id="logo" className={styles.open_trade_text} rel="noreferrer">ОТКРЫТЬ ОБМЕН</a>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}