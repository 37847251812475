import styles from "../../../styles/components/ErrorAddingPopUp.module.scss"

import error from "../../../assets/svg/error.svg"
import cancel from "../../../assets/svg/cancel_banner.svg"
import {HandySvg} from "handy-svg";
import {Fragment, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
export const ErrorAddingPopUp = (props) => {
    const [IsOPen, SetIsOPen] = useState(true)
    return (
        <Transition appear show={IsOPen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => {
                SetIsOPen(false)
                props.CloseMainPopUp(false)
            }}>
                <div className="fixed inset-0 overflow-y-auto bg-opacity-40 bg-black">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel>
                                <div className={styles.error_loading}>
                                    <div>
                                        <HandySvg src={cancel} className={styles.cross_3} onClick={() => SetIsOPen(false)}/>
                                        <HandySvg src={error} className={styles.error_svg}/>
                                        <h2 className={styles.error_loading_prgrf}>Ошибка добавления аккаунта!</h2>
                                        <span className={styles.error_loading_text}>{props.text}</span>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>)
}