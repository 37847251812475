import styles from "../../../styles/components/ItemsRows.module.scss"
import {ItemRows} from "./ItemRow";
import {StoreLoadInventory} from "../../../store/store";
import {FilterInventory, GroupingItems, SortingItems} from "../../../utils/inventory";

export const ItemsRows = () => {
    const ActiveSource = StoreLoadInventory((state) => state.ActiveSource)
    const Items = StoreLoadInventory((state) => state.Items)
    const Tradable = StoreLoadInventory((state) => state.Tradable)
    const Marketrable = StoreLoadInventory((state) => state.Marketrable)
    const SortingQuantity = StoreLoadInventory((state) => state.SortingQuantity)
    const SortingPrice = StoreLoadInventory((state) => state.SortingPrice)
    let items = FilterInventory(Items, Tradable, Marketrable)
    items = GroupingItems(items)
    items = SortingItems(items, SortingQuantity, SortingPrice, ActiveSource)
    return (
        <section className={styles.sect_card}>
            <div className={styles.all_card}>
                {Object.values(items).map((item, number) => <ItemRows item={item} number={number}/>)}
            </div>
        </section>
    );
}