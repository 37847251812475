import styles from "../../../styles/components/Policy.module.scss";


export const Policy = () => {
    return (
        <section className={styles.policy_wrap_div}>
            <span className={styles.policy_par_span}>Политика конфиденциальности</span>
            <div className={styles.card_div}>
                <span className={styles.card_par_span}>УСЛОВИЯ ПОЛЬЗОВАНИЯ Сайтом</span>
                <span className={styles.card_text_span}>
INTERNATIONAL BUSINESS SYSTEMS S.R.L. как администратор принимает настоящие Условия Пользования, в которых указаны права и обязанности Пользователя и представляют собой юридически обязывающее соглашение для обеих сторон. Настоящие Условия Пользования влияют на права Пользователя и возлагают определенные обязательства при использовании Сайта, поэтому Пользователь должен внимательно их прочитать.
                </span>
                <span className={styles.card_par_span}>1. Термины и определения</span>
                <span className={styles.card_text_span}>
1.1.
«Агентский договор»
Агентский договор, заключенный между Пользователем и https://steaminventory.ru на условиях, доступных внутри Сайта https://steaminventory.ru/policy . Агентский договор является неотъемлемой частью Условий Пользования.
«Ставка»
Предложение Пользователя для https://steaminventory.ru заключить Сделку по продаже или покупке Предметов.
«Сделка»
Продажа или покупка Позиций в соответствии с настоящими Условиями Пользования.
«Предметы»
Виртуальные товары для использования в компьютерных играх, перечисленных на страницах сайта https://steaminventory.ru:
«https://steaminventory.ru».
«Личный аккаунт»
Аккаунт, который ведет Пользователь в целях занятия торговлей с помощью https://steaminventory.ru
«Услуги»
Услуги, предоставляемые https://steaminventory.ru через Веб-Сайт.
«Условия Пользования»
Соглашение между Пользователем и https://steaminventory.ru доступно внутри Сайта в разделе Пользовательское соглашения и Политика конфиденциальности https://steaminventory.ru/policy
«Пользователь»
Физическое лицо, которое использует Веб-сайт в соответствии с Условиями Пользования.
«Веб-Сайт»
Совокупность информации, текстов, графических элементов, дизайна, изображений, фото и видеоматериалов и иных результатов интеллектуальной деятельности, а также программ для ЭВМ, содержащихся в информационной системе, обеспечивающей доступность такой информации в сети Интернет по сетевому адресу: https://steaminventory.ru.
1.2.
Настоящие Условия Пользования регулируются и толкуются в соответствии с законами РФ, если иное прямо не предусмотрено. Все споры и разногласия, возникающие на основании или в связи с настоящим сайтом и настоящими Условиями Пользования, должны быть переданы в соответствующие суды по месту регистрации администратора https://steaminventory.ru.
                </span>

                <span className={styles.card_par_span}>2. Использование Веб-Сайта.</span>
                <span className={styles.card_text_span}>
2.1.
В соответствии с данными Условиями Пользования https://steaminventory.ru настоящим документом предоставляет Пользователю неисключительную и не подлежащую передаче ограниченную лицензию на использование Сайта в соответствии с изложенными ниже положениями. Все права, прямо не предоставленные в настоящем документе, полностью защищены https://steaminventory.ru, его рекламодателями и лицензиарами.
2.2.
Настоящим документом https://steaminventory.ru также предоставляет Пользователю разрешение на использование контента Сайта, при условии, что Пользователь (1) использует его исключительно для личных, некоммерческих целей (кроме торговли Предметами, размещенными на Веб-Сайте); (2) не изменяет или не продает содержимое Сайта; и (3) не воспроизводит, демонстрирует, публично представляет, распространяет и не использует иным образом содержимое Сайта в каких-либо общественных или коммерческих целях, включая использование контента на любом другом Веб-сайте.
2.3.
Посредством своих Услуг https://steaminventory.ru помогает Пользователям покупать и продавать Предметы. https://steaminventory.ru получает плату от Пользователей за налаживание их связи друг с другом; однако https://steaminventory.ru не предоставляет или иным образом не продает Предметы, размещенные на Боте, и, за исключением выполнения функций депозитария, https://steaminventory.ru не действует в качестве агента, брокера, сотрудника или работодателя любого Пользователя.
2.4.
https://steaminventory.ru действует только в качестве интернет-магазина, а https://steaminventory.ru не является и не будет участником какого-либо соглашения между Пользователями относительно покупки любых Предметов, размещенных на сайте. https://steaminventory.ru не имеет контроля над и не дает никаких объяснений относительно каких-либо качеств или характеристик Предметов. https://steaminventory.ru не гарантирует, что любые Предметы, цены, налоги или описания являются точными, полными или надежными. Пользователи несут ответственность за уплату всех соответствующих налогов, включая налоги на продажи и пользование, но не ограничиваясь ими.
                </span>

                <span className={styles.card_par_span}>3. Торговый процесс</span>
                <span className={styles.card_text_span}>
3.1.
https://steaminventory.ru позволяет Пользователям продать Предметы через https://steaminventory.ru. Процедура торгового процесса подробно описана внутри Сайта в разделе Инструкции
3.2.
Сделка выполняется https://steaminventory.ru в соответствии с условиями, указанными в Агентском договоре.
3.3.
Пользователь имеет право отменить Продажу в любое время до того, как предметы были переданы Сайту в целях совершения Сделки.
3.4.
В случае покупки Предметов, Сделка должна содержать подробную спецификацию некоторых Предметов. https://steaminventory.ru выполняет Сделку и уведомляет Пользователя внутри Сайта. Получив уведомление, Пользователь имеет право принять Сделку и получить Предметы.
3.5.
В случае продажи Предметов, Сделка должна содержать подробную спецификацию определенных Предметов и цену их реализации. Все остальные условия Сделки определяются по собственному усмотрению https://steaminventory.ru.
3.6.
На основании цен, предложенных администрацией https://steaminventory.ru размещает информацию в соответствии с пунктом 3.5 Условий Пользования Веб-Сайтом.
3.7.
Любой Пользователь имеет возможность совершать Сделки через https://steaminventory.ru и реагировать на Ставки, размещенные администрацией в соответствии с пунктами 3.4.-3.5. https://steaminventory.ru оформляет Сделку с первым отреагировавшим Пользователем.
3.8.
https://steaminventory.ru осуществляет Сделку и уведомляет Пользователя в соответствии со Ставкой. Получив уведомление, Пользователь имеет право предоставить Предметы https://steaminventory.ru для дальнейшей компенсационной передачи Предметов Пользователю, который первым отреагировал на актуальную Ставку. После передачи Предметов Пользователя https://steaminventory.ru получает средства от Сделки и переводит их на счет Пользователя.

                </span>

                <span className={styles.card_par_span}>4. Взносы</span>
                <span className={styles.card_text_span}>
4.1.
В соответствии с настоящими Условиями Пользования Пользователь соглашается заплатить и несет ответственность за все соответствующие платежи и взносы за Услуги. Взносы https://steaminventory.ru составляют от 0 до 10% в зависимости от методов пополнения Счета пользователя.
4.2.
Все взносы, которые Пользователь должен заплатить третьим лицам (в том числе Финансовому учреждению), не подпадают под действие настоящих Условий Пользования. Все взносы, которые https://steaminventory.ru должен заплатить третьим лицам (в том числе Финансовому учреждению), связанные с переводом средств на Счет пользователя, покрываются за счет Пользователя.
4.3.
Сумма взносов, указанных в пункте 4.1 Условий Пользования, может быть снижена https://steaminventory.ru за счет средств, полученных для выполнения Сделки.
4.4.
https://steaminventory.ru предусматривает дополнительные взносы в соответствии с Агентским договором.
4.5.
https://steaminventory.ru удерживает свои взносы, указанные в пункте 4.4 Условий Пользования, в соответствии с условиями, указанными в Агентском договоре.
4.5.1
Все Сделки по приобретению Инвентаря не подлежат отмене. Средства Принципала, использованные для совершения Сделки по приобретению Инвентаря, не подлежат возврату
4.6.
Перевод средств на https://steaminventory.ru может быть произведен с помощью:
4.6.1.
Банковского перевода на счет https://steaminventory.ru или через платежных посредников, имеющих контракты с https://steaminventory.ru;
4.6.2.
Перевода электронных денег на счет https://steaminventory.ru;
4.6.3.
Перевода других денежных единиц на счет https://steaminventory.ru для оплаты услуг электронной коммерции.
4.7.
https://steaminventory.ru обеспечивает вывод средств Пользователя в течении 3 (трех) рабочих дней после запроса Пользователя, поданного через Сайта. Вывод средств Пользователя осуществляется путем перечисления на платежные реквизиты Пользователя (проверьте доступные варианты в разделе "Профиль/Вывод средств"). На вывод накладывается ограничение в 8 дней на только что введеные деньги для противодействия фродовым операциям.
На боте запрещен вывод фиатных средств пользователя в цифровую валюту, так же как и вывод/обмен цифровой валюты в фиатные деньги.
4.8.
В соответствии с настоящими Условиями использования Пользователь соглашается с тем, что:
4.8.1.
Пользователь прочитал и согласен с условиями предоставления услуг «Яндекс.Деньги».
4.8.2.
Пользователь соглашается с тем, что финансовые обязательства https://steaminventory.ru перед Пользователем выполнены путем зачисления соответствующей суммы на электронные средства оплаты Пользователя в платежном сервисе «Яндекс.Деньги».
                </span>

                <span className={styles.card_par_span}>5. Правовая оговорка</span>
                <span className={styles.card_text_span}>
5.1
https://steaminventory.ru категорически снимает с себя какие-либо гарантии в отношении работы Сайта, включая обязанности по отсутствию недобросовестности и отсутствию качественного исполнения.
5.2
Вся информация на Веб-Сайте должна рассматриваться «в том виде, в котором оно есть» без каких-либо явных или косвенных представлений или гарантий отсутствия нарушений, соответствия Сайта ожиданиям Пользователей и его пригодности для определенной цели.
5.3
https://steaminventory.ru может в любое время и без предварительного предупреждения удалить или временно прекратить услуги, предоставляемые Пользователям в соответствии с настоящими Условиями Пользования.
                </span>

                <span className={styles.card_par_span}>6. Ответственность</span>
                <span className={styles.card_text_span}>
6.1.
https://steaminventory.ru не несет ответственности за какие-либо прямые, косвенные, случайные, последующие, специальные, типовые, штрафные или иные убытки или ущерб (включая, помимо прочего, убытки в результате упущенной выгоды, упущенных сделок, использования данных или другие нематериальные убытки), связанные с любым контрактом, деликтом (включая, в частности, небрежность и объективную ответственность) или иной правовой теорией, независимо от причин, вызванные или относящийся каким-либо образом к Веб-Сайту, его содержанию или любой информации на Веб-Сайте, или любым ошибкам или неточностям, содержащимся в нем.
6.2.
https://steaminventory.ru не несет ответственности за:
6.2.1.
То, что аппаратное обеспечение, программное обеспечение или подключение к Интернету не функционируют должным образом;
6.2.2.
Любое приостановление или отказ от принятия платежей, если https://steaminventory.ru обоснованно полагает, что они были сделаны обманным путем или без соответствующих полномочий;
6.2.3.
Непредвиденные обстоятельства, препятствующие надлежащей работе, несмотря на любые разумные меры предосторожности, предпринятые https://steaminventory.ru (отключения электроэнергии, пожар, наводнение, похищение имущества, поломки оборудования, хакерские атаки, внутренние механические или системные сбои, а также простои Сайта).
                </span>

                <span className={styles.card_par_span}>7. Ссылки на другие сайты</span>
                <span className={styles.card_text_span}>
7.1.
Веб-Сайт может содержать ссылки на сторонние интернет-сайты, ресурсы, рекламодателей, услуги, специальные предложения или другие события или действия, которые не принадлежат или не администрируются https://steaminventory.ru. https://steaminventory.ru не несет ответственности за содержимое таких сторонних сайтов, материалов, информации или услуг. Доступ и использование таких веб-сайтов, материалов, информации или услуг осуществляется Пользователем под свою ответственность.
                </span>

                <span className={styles.card_par_span}>8. Изменения, продолжительность и прекращение действия</span>
                <span className={styles.card_text_span}>
8.1.
Настоящие Условия Пользования подлежат изменению, дополнению и модифицированию. Продолжая использовать Сайта, Пользователь считается обязанным придерживаться измененной версии настоящих Условий Пользования. В случае изменения Условий Пользования, Пользователю будет отправлено уведомление в разделе "Профиль". Если какие-либо изменения Условий Пользования неприемлемы для Пользователя, единственным средством защиты Пользователя является удаление его аккаунта и прекращение использование Сайта.
8.2.
Услуги и информация, содержащиеся на Веб-Сайте, имеют неопределенный срок действия. https://steaminventory.ru может полностью или частично прекратить или приостановить предоставление услуги или информации, размещенной на Веб-Сайте в любое время и без предварительного уведомления.
                </span>

                <span className={styles.card_par_span}>9. Рассмотрение споров</span>
                <span className={styles.card_text_span}>
9.1.
Если спор, разногласие или претензия возникает на основании Условий Пользования или связано с их исполнением, прекращением действия или расторжением Условий использования, обе стороны соглашаются добросовестно попытаться урегулировать любой спор, разногласие или претензию путем переговоров. Если стороной, заявляющей претензию, является https://steaminventory.ru, отправляется сообщение с претензией на имя Пользователя. В рассматриваемом сообщении должны содержаться суть претензии и доказательства в подтверждение претензии.
9.2.
Сторона, получившая претензию, должна ответить стороне, заявившей претензию, в течение 3 (трех) рабочих дней после получения претензии.
9.3.
В случае отсутствия ответа на претензию в течение 10 (десяти) рабочих дней с даты отправки или если Стороны не смогли разрешить спор, должен быть подан иск в соответствующий суд.
                </span>

                <span className={styles.card_par_span}>ВОЗВРАТ ДЕНЕЖНЫХ СРЕДСТВ</span>
                <span className={styles.card_text_span}>
Возврат денежных средств возможен только до момента получения вами товара, либо после возврата Вами товара в течение 24 часов с момента его получения. Обращаем Ваше внимание, возврат денежных средств осуществляется исключительно на банковскую карту или кошелек с которого была произведена оплата.

ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ
https://steaminventory.ru принимает настоящую Политику Конфиденциальности, которая определяет, как собирается и обрабатывается информация о Пользователе, полученная с помощью Сайта. Пожалуйста, не используйте Сайт, если вы не согласны с Политикой Конфиденциальности.
                </span>
                <span className={styles.card_par_span}>1. Термины и определения</span>
                <span className={styles.card_text_span}>
1.1.
Следующие термины, используемые в настоящей Политике Конфиденциальности, имеют значения, изложенные в этом пункте:
«Профиль»
Аккаунт, который ведет Пользователь в целях занятия торговлей с помощью https://steaminventory.ru.
«Персональные данные»
Любые данные, относящиеся к живому человеку, который идентифицирован или может быть идентифицирован с помощью этих данных или данных вместе с другой информацией, которая находится в распоряжении или, скорее всего, будет предоставлена в распоряжения https://steaminventory.ru.
«Политика Конфиденциальности»
Настоящий документ.
Обработка
Любая операция или комплекс операций, выполняемых по персональным данным, независимо от того, осуществляется ли выполнение с помощью таких автоматизированных средств, как сбор, запись, организация, хранение, адаптация или изменение, поиск, согласование, использование, раскрытие путем передачи, распространения или предоставления доступа любым другим способом, упорядочение или комбинирование, блокирование, удаление или уничтожение.
«Условия Пользования»
Соглашение между Пользователем и https://steaminventory.ru доступно по адресу https://steaminventory.ru/policy
«Пользователь»
Физическое лицо, которое использует Сайт в соответствии с Условиями Пользования.
«Веб-Сайт»
Совокупность информации, текстов, графических элементов, дизайна, изображений, фото и видеоматериалов и иных результатов интеллектуальной деятельности, а также программ для ЭВМ, содержащихся в информационной системе telegram, обеспечивающей доступность такой информации в сети Интернет по сетевому адресу: https://https://steaminventory.ru/.
1.2.
Политика Конфиденциальности регулируется и толкуется в соответствии с законодательством страны, где находится юридический адрес https://steaminventory.ru, если иное прямо не предусмотрено.
                </span>
                <span className={styles.card_par_span}>2. Собранная информация</span>
                <span className={styles.card_text_span}>
2.1.
Может быть собрана следующая информация:
2.1.1.
Информация о трафике;
2.1.2.
Персональные данные;
2.2.
Информация о трафике не является персональными данными, однако https://steaminventory.ru использует файлы cookie или подобные технологии, которые могут в некоторой степени идентифицировать Пользователя. Cookie-файлы представляют собой небольшие текстовые файлы, которые позволяют https://steaminventory.ru собирать информацию о активности просмотров разделов в https://steaminventory.ru, таких как разделы, посещенные Пользователем, и время, потраченное пользователем на просмотр разделов.
2.3.
https://steaminventory.ru собирает Персональные данные, которые Пользователь предоставляет непосредственно в соответствии с Условиями Пользования для выполнения любых транзакций на https://steaminventory.ru или использования услуг https://steaminventory.ru. Персональные данные могут включать:
2.3.1.
Контактная информация, такая как имя, домашний адрес и адрес электронной почты;
2.3.2.
Финансовая информация, такая как номера банковских счетов, выписка из банка и торговая информация;
2.3.3.
Информация для установления личности, такая как изображения документа, удостоверяющий личность, выданного государством;
2.3.4.
Информация для проверки места проживания.
                </span>
                <span className={styles.card_par_span}>3. Цели обработки</span>
                <span className={styles.card_text_span}>
3.1.
https://steaminventory.ru использует Персональные данные для того, чтобы:
3.1.1.
Обрабатывать транзакции Пользователя;
3.1.2.
Отвечать на сервисные запросы Пользователя и требования об оказании помощи;
3.1.3.
Связываться с вами по поводу новостей, обновлений, промоакций и новых услуг (сообщения по маркетингу) https://steaminventory.ru . Если вы хотите прекратить получать маркетинговые сообщения от нас, отключите уведомления в разделе "Профиль", чтобы отказаться от получения.
3.2.
https://steaminventory.ru использует Информацию о трафике, чтобы:
3.2.1.
Понимать структуру трафика и количество посетителей Сайта;
3.2.2.
Понимать, как Пользователь использует и взаимодействует с Ботом;
3.2.3.
Улучшать функциональность Сайта;
3.2.4.
Накопить статистические данные для аудита, исследований, моделирования и отчетности для наших рекламодателей и других партнеров;
3.2.5.
Предоставление Пользователю соответствующей рекламы и контента.
                </span>
                <span className={styles.card_par_span}>4. Безопасность и передача персональных данных третьим лицам</span>
                <span className={styles.card_text_span}>
4.1.
https://steaminventory.ru сохраняет за собой право хранить информацию, включая Персональные данные, собранные в соответствии с п. 2.3 Политики Конфиденциальности, в течение периода до 5 лет. https://steaminventory.ru использует стандартные соответствующие меры безопасности в отношении потери, неправильного использования или изменения такой информации. Однако https://steaminventory.ru не может гарантировать полную неуязвимость системы безопасности от противозаконных действий третьих лиц, и Пользователь не должен реагировать на любые претензии в отношении какого-либо ущерба, причиненного незаконными действиями третьих лиц.
4.2.
Пользователь соглашается с тем, что https://steaminventory.ru может делиться и передавать информацию, включая Персональные данные, собранные в соответствии с п. 2.3 Политики Конфиденциальности, третьей стороне, которая является стороной в договоре как с https://steaminventory.ru, так и с Пользователем. У этой третьей стороны могут быть собственные принципы и методы обработки данных. В любом случае https://steaminventory.ru не несет ответственности за такие принципы и методы какой-либо третьей стороны.
4.3.
Независимо от того, передает ли https://steaminventory.ru свои права третьей стороне или участвует в слиянии или аналогичном виде передачи активов, Пользователь предоставляет https://steaminventory.ru право передавать Персональные данные стороне, приобретающей компании или получателю активов.
4.4.
Мы гарантируем безопасность платежей при оплате банковской картой. Процессинговый центр соответствует международным требованиями PCI DSS для обеспечения безопасной обработки реквизитов банковской карты плательщика. Мы применяем технический стандарт 3DS безопасности, созданный Visa и MasterCard, для дополнительной защиты транзакций через Интернет (Card-holder Not Present).
                </span><
                span className={styles.card_par_span}>
5. Изменения в Политике Конфиденциальности</span>
                <span className={styles.card_text_span}>
5.1
https://steaminventory.ru оставляет за собой право добавлять, изменять, обновлять или модифицировать Политику конфиденциальности. Любое такое изменение, обновление или модификация вступают в силу немедленно после размещения на Веб-Сайте, и Пользователь признает, что продолжение использования Сайта является самостоятельным и соответствующим решением для обеспечения выполнения такого изменения. Если какое-либо изменение неприемлемо, единственным выходом Пользователя является не использование Сайта.
                </span>
            </div>
        </section>
    )
}