import {HandySvg} from "handy-svg";
import cancel from "../../../assets/svg/cancel_banner.svg";
import star_yellow from "../../../assets/svg/star_yellow.svg";
import crown_yellow from "../../../assets/svg/crown_yellow.svg";
import white_success from "../../../assets/svg/white_success.svg";
import styles from "../../../styles/components/License.module.scss";
import {StoreBasket, StoreSelectLicense, StoreUser} from "../../../store/store";
import {Fragment, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {BuyLicenseAPI} from "../../../services/api";
import {SuccessAddedPopUp} from "./SuccessAddedPopUp";


export const License = () => {
    const SetIsOpen = StoreSelectLicense((state) => state.SetIsOpen)
    const SetIsOpenError = StoreBasket((state) => state.SetIsOpenError)
    const SetErrorText = StoreBasket((state) => state.SetErrorText)

    const Balance = StoreUser((state) => state.Balance)
    const SetBalance = StoreUser((state) => state.SetBalance)

    const ActiveLicense = StoreUser((state) => state.Limits)
    const SetActiveLicense = StoreUser((state) => state.SetLimits)
    const [selectLVL, setSelectLVL] = useState(ActiveLicense.lvl)
    const [statusActive, setStatusActive] = useState(false);

    const BuyLicense = async (select_lvl) => {
        const res = await BuyLicenseAPI(select_lvl)
        if (res.status){
            SetActiveLicense(res.lvl)
            setStatusActive(true)
            if (res.lvl.lvl === "trade"){
                SetBalance(Balance - 100)
            }
            if (res.lvl.lvl === "pro"){
                SetBalance(Balance - 250)
            }
        }
        else{
            switch (res.error) {
                case "License LVL not found":
                    SetErrorText("Лицензия LVL не найдена")
                    break
                case "You already have this license":
                    SetErrorText("У вас уже есть эта лицензия")
                    break
                case "You cannot downgrade your license":
                    SetErrorText("Вы не можете понизить уровень своей лицензии")
                    break
                case "Not enough money":
                    SetErrorText("Недостаточно денег")
                    break
                default:
                    SetErrorText("Неизвестная ошибка")
                    break
            }
            SetIsOpenError(true);
        }
    }

    return (
        <>
            {statusActive ? <SuccessAddedPopUp title={"Подписка успешно активирована"} desc={`Вы купили подписку уровня ${ActiveLicense.lvl}`}/> : null}
            <section className={styles.select_wrap}>
                <div className={styles.select_top_wrap_span}>
                    <span className={styles.select_top_span}>Выберите подписку</span>
                    <HandySvg src={cancel} className={styles.cancel_popUpSvg} onClick={() => SetIsOpen(false)}/>
                </div>
                <div className={styles.cards_wrap}>
                    <div className={ActiveLicense.lvl === "trade" || ActiveLicense.lvl === "pro" ? styles.card_wrap_active : styles.card_wrap} onMouseOver={() => setSelectLVL("trade")}>
                        <div className={styles.top_card_wrap}>
                            <HandySvg src={star_yellow} className={styles.card_svg_lvl}/>
                            <span className={styles.card_lvl_span}>Trade</span>
                        </div>
                        <div className={styles.card_price_wrap}>
                            <span className={styles.card_price_span}>₽ 100</span>
                            <span className={styles.card_time_span}>  / в месяц</span>
                        </div>
                        <div className={styles.card_description_wrap}>
                            <span className={styles.card_description_span}>Что вы получаете:</span>
                        </div>
                        <div>
                            <div className={styles.card_description_item_one_wrap}>
                                <HandySvg src={white_success} className={styles.card_svg_white_success_one}/>
                                <span className={styles.card_description_item_span_one}>250 отчетов в месяц</span>
                            </div>
                            <div className={styles.card_description_item_wrap}>
                                <HandySvg src={white_success} className={styles.card_svg_white_success}/>
                                <span className={styles.card_description_item_span}>Общий отчет</span>
                            </div>
                            <div className={styles.card_description_item_wrap}>
                                <HandySvg src={white_success} className={styles.card_svg_white_success}/>
                                <span className={styles.card_description_item_span}>50 аккаунтов</span>
                            </div>
                        </div>
                        <div className={styles.active_license_btn_wrap_desktop} onClick={async () => BuyLicense("trade")}>
                            <div className={styles.active_license_btn_desktop}>
                                <span className={styles.active_license_span_desktop}>АКТИВИРОВАТЬ</span>
                            </div>
                        </div>
                    </div>
                    <div className={ActiveLicense.lvl === "pro" ? styles.card_wrap_active : styles.card_wrap} onMouseOver={() => setSelectLVL("pro")}>
                        <div className={styles.top_card_wrap}>
                            <HandySvg src={crown_yellow} className={styles.card_svg_crown}/>
                            <span className={styles.card_lvl_span}>Trade</span>
                            <div className={styles.card_popular_wrap}>
                                <span className={styles.card_popular_span}>популярный</span>
                            </div>
                        </div>
                        <div className={styles.card_price_wrap}>
                            <span className={styles.card_price_span}>₽ 250</span>
                            <span className={styles.card_time_span}>  / в месяц</span>
                        </div>
                        <div className={styles.card_description_wrap}>
                            <span className={styles.card_description_span}>Что вы получаете:</span>
                        </div>
                        <div>
                            <div className={styles.card_description_item_one_wrap}>
                                <HandySvg src={white_success} className={styles.card_svg_white_success_one}/>
                                <span className={styles.card_description_item_span_one}>Безлимитный тариф</span>
                            </div>
                        </div>
                        <div className={styles.active_license_btn_wrap_right_desktop} onClick={async () => BuyLicense("pro")}>
                            <div className={styles.active_license_btn_desktop}>
                                <span className={styles.active_license_span_desktop}>АКТИВИРОВАТЬ</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.active_license_btn_mobile} onClick={async () => BuyLicense(selectLVL)}>
                    <span className={styles.active_license_span_mobile}>АКТИВИРОВАТЬ</span>
                </div>
            </section>
        </>
    )
}

export const LicenseWrap = () => {
    const SetIsOpen = StoreSelectLicense((state) => state.SetIsOpen)
    const isOpen = StoreSelectLicense((state) => state.isOpen)
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => SetIsOpen(false)}>
                <div className="fixed inset-0 overflow-y-auto bg-opacity-40 bg-black">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel>
                                <License/>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}