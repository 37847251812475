import {GetCookie, SetCookie} from "./Cookies";
import {GetInventoryDeprecated, Profile} from "../services/api";
import {CreateParamsStemId} from "./Steam";

export const GetAppIdByName = {
    "CS:GO": 730,
    "Dota": 570,
    "Rust": 252490,
    "TF": 440,
    "AllGamesDesktop": [730, 570, 252490, 440],
    "AllGamesMobile": [730, 570, 252490, 440],
    "AllGames": [730, 570, 252490, 440]
}

export const GetFullNameCurrency = {
    "RUB": "Рубль (₽)",
    "USD": "Доллар ($)",
    "EURO": "Евро (€)",
    "YUAN": "Юань (¥)",
    "TENGE": "Тенге (₸)",
    "BEL RUB": "Бел. рубль (Br)"
}

export const GetFullFilter = {
    "All": "Все",
    "Alone": "Одиночный",
    "Group": "Групповой",
    "Rows": "Таблица",
    "Grid": "Сетка",
    "Tradable": "Tradable",
    "UnTradable": "UnTradable",
    "Marketrable": "Marketrable",
    "UnMarketrable": "UnMarketrable",
}

export const GetCodeCurrency = {
    "RUB": "₽",
    "USD": "$",
    "EURO": "€",
    "YUAN": "¥",
    "TENGE": "₸",
    "BEL RUB": "Br"
}

export const ReplaceConst = [
    {"key": "Minimal Wear", "value": "MW"},
    {"key": "Well-Worn", "value": "WW"},
    {"key": "Field-Tested", "value": "FT"},
    {"key": "Battle-Scarred", "value": "BS"},
    {"key": "Factory New", "value": "FN"},

    {"key": "Souvenir", "value": "SV"},
    {"key": "StatTrak™", "value": "ST "},

    {"key": "XM1014 |", "value": ""},
    {"key": "USP-S |", "value": ""},
    {"key": "UMP-45 |", "value": ""},
    {"key": "Tec-9 |", "value": ""},
    {"key": "SSG 08 |", "value": ""},
    {"key": "SG 553 |", "value": ""},
    {"key": "Sawed-Off |", "value": ""},
    {"key": "R8 Revolver |", "value": ""},
    {"key": "P90 |", "value": ""},
    {"key": "P250 |", "value": ""},
    {"key": "P2000 |", "value": ""},
    {"key": "Nova |", "value": ""},
    {"key": "Negev |", "value": ""},
    {"key": "MP9 |", "value": ""},
    {"key": "MP7 |", "value": ""},
    {"key": "MP5-SD |", "value": ""},
    {"key": "MAG-7 |", "value": ""},
    {"key": "MAC-10 |", "value": ""},
    {"key": "M4A4 |", "value": ""},
    {"key": "M4A1-S |", "value": ""},
    {"key": "M249 |", "value": ""},
    {"key": "Galil AR |", "value": ""},
    {"key": "G3SG1 |", "value": ""},
    {"key": "Five-SeveN |", "value": ""},
    {"key": "FAMAS |", "value": ""},
    {"key": "Dual Berettas |", "value": ""},
    {"key": "Desert Eagle |", "value": ""},
    {"key": "CZ75-Auto |", "value": ""},
    {"key": "AWP |", "value": ""},
    {"key": "AUG |", "value": ""},
    {"key": "AK-47 |", "value": ""},
    {"key": "Glock-18 |", "value": ""},

    {"key": "Sticker |", "value": ""},
    {"key": "Souvenir Token", "value": ""},
    {"key": "Gold Coin", "value": ""},
    {"key": "Sealed Graffiti |", "value": ""},
]

export const DownloadInventory = (Items, Filename) => {
    const data = JSON.stringify(Items);
    const blob = new Blob([data], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = Filename + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const GetPlaceHolderProfile = () => {
    const last_inventory = GetCookie("last_inventory")
    if (last_inventory === null){
        return "https://steamcommunity.com/profiles/7656119..."
    }
    return last_inventory
}

export const LoadInventory = async (TradeLink, Game, SetErrorLoadingPopUp, SetItems, SetErrorText, params) => {
    const AppId = GetAppIdByName[Game];
    if (!params) return;
    try {
        const resp = await GetInventoryDeprecated({ AppId: AppId, ...params });
        if (resp["status"]) {
            SetCookie("last_inventory", TradeLink, 7);
            SetItems(resp["inventory"]);
        } else if (resp.hasOwnProperty("error")) {
            SetErrorLoadingPopUp(true);
            SetErrorText(resp["error"])
        }
    } catch (err) {
        SetErrorLoadingPopUp(true);
    }
};

export const LoadProfile = async (TradeLink, SetUserName, SetUserImage, SetInventoryId, params) => {
    if (!params) return;
    try {
        const resp = await Profile(params);
        if (resp["status"]) {
            SetUserName(resp.user_profile.personaname);
            SetUserImage(resp.user_profile.avatarfull);
            SetInventoryId(resp.user_profile.steamid);
        } else {
        }
    } catch (err) {
    }
};


export const FilterMarketrable = (Items, Value) => {
    if (Value === "All"){
        return Items
    }
    const Query = Value === "Marketrable" ? 1 : 0
    return Object.values(Items).filter(item => item["marketable"] === Query);
}


export const FilterTradable = (Items, Value) => {
    if (Value === "All"){
        return Items
    }
    const Query = Value === "Tradable" ? 1 : 0
    return Object.values(Items).filter(item => item["tradable"] === Query);
}

export const SumInventory = (Items, ActiveSource) => {
    let value = 0
    for (const item in Items) {
        value += Items[item][ActiveSource]?.prices?.median || 0
    }
    return value
}

export const SortingPriceItems = (Items, value, ActiveSource) => {
    if (value !== "default") {
        const sortedItems = Object.entries(Items).sort(([, a], [, b]) => (a[ActiveSource]?.prices?.median || 0) - (b[ActiveSource]?.prices?.median || 0));
        const sortedDict = {};
        for (const [key, value] of sortedItems) {
            sortedDict[key] = value;
        }
        if (value === "descending") {
            return Object.fromEntries(Object.entries(sortedDict).reverse());
        }
        return sortedDict;
    }
    return Items;
};


export const SortingQuantityItems = (Items, value) => {
    if (value !== "default") {
        const sortedItems = Object.entries(Items).sort(([, a], [, b]) => a.count - b.count);
        const sortedDict = {};
        for (const [key, value] of sortedItems) {
            sortedDict[key] = value;
        }
        if (value === "descending") {
            return Object.fromEntries(Object.entries(sortedDict).reverse());
        }
        return sortedDict;
    }
    return Items;
};

export const SortingItems = (Items, Quantity, Price, ActiveSource) => {
    Items = SortingQuantityItems(Items, Quantity)
    Items = SortingPriceItems(Items, Price, ActiveSource)
    return Items
}

export const FilterInventory = (Items, Tradable, Marketrable) => {
    Items = FilterMarketrable(Items, Marketrable)
    Items = FilterTradable(Items, Tradable)
    return Items
}

const ReplaceName = (Name) => {
    for (const replaceNameKey in ReplaceConst) {
        const replaceDict = ReplaceConst[replaceNameKey]
        Name = Name.replace(replaceDict["key"], replaceDict["value"])
    }
    return Name
}


export const ReplaceNames = (Items) => {
    const NewItems = []
    for (const ItemIndex in Items) {
        const Item = Object.assign({}, Items[ItemIndex])
        Item.full_market_hash_name = Item["market_hash_name"]
        Item.market_hash_name = ReplaceName(Item["market_hash_name"])
        NewItems.push(Item)
    }
    return NewItems
}

export const Scroll = (Items, Start, Step) => {
    return Items.slice(Start, Start + Step)
}

export const GroupingItems = (Items) => {
    const GroupArray = {};
    for (const item of Items) {
        const group_id = `${item?.full_market_hash_name}?tradable=${item?.tradable}&marketable=${item?.marketable}`;
        if (!GroupArray[group_id]) {
            GroupArray[group_id] = item
            GroupArray[group_id].Items = []
            GroupArray[group_id].Ids = []
        }
        GroupArray[group_id].Items.push(item);
        GroupArray[group_id].Ids.push(item["full id"]);
    }
    for (const key in GroupArray) {
        GroupArray[key].count = GroupArray[key].Ids.length
    }
    return GroupArray
}

export const FindItemToAdd = (Items, GroupId, BasketItemsIds) => {
    for (const Item of Items) {
        if (Item.group_id === GroupId && (!BasketItemsIds.includes(Item["full id"]))){
            if (Item.marketable && Item.tradable && Item["Skin Sorter"]?.prices?.median){
                Item.market_hash_name = ReplaceName(Item["market_hash_name"])
                return Item
            }
        }
    }
}

export async function CreateReportAlert() {
    // Задержка выполнения асинхронной функции на 0 миллисекунд
    await new Promise(resolve => setTimeout(resolve, 1000));

    alert("Создаем отчет, это может занять определенное время (1-5 мин), не закрывайте страницу")
}