import {HandySvg} from 'handy-svg';
import basket from "../../../assets/svg/basket.svg"
import styles from "../../../styles/components/SortingRows.module.scss"
import sorting_down_item from "../../../assets/svg/sorting_down_items.svg"
import sorting_up_item from "../../../assets/svg/sorting_up_items.svg"
import {StoreAuthPopup, StoreBasket, StoreLoadInventory, StoreUser} from "../../../store/store";

export const SortingRows = () => {
    const SetSortingQuantity = StoreLoadInventory((state) => state.SetSortingQuantity)
    const SetSortingPrice = StoreLoadInventory((state) => state.SetSortingPrice)
    const BasketItems = StoreBasket((state) => state.Items)
    const IsAuth = StoreUser((state) => state.IsAuth)
    const SetIsOpenBasket = StoreBasket((state) => state.SetIsOpen)
    const SetIsOpenAuth = StoreAuthPopup((state) => state.SetIsOpen)
    return (
        <section>
            <div className={styles.filter_dub}>
                <div className={styles.skin_wrap}>
                    <span className={styles.skin}>Скин</span>
                </div>
                <div className={styles.name_wrap}>
                    <span className={styles.name}>Название скина</span>
                </div>
                <div className={styles.quantity_filter}>
                    <span className={styles.quantity}>Количество</span>
                    <div className="cursor-pointer">
                        <HandySvg onClick={() => {
                            SetSortingQuantity("increase")
                            SetSortingPrice("default")
                        }} src={sorting_up_item} className={styles.arrows_1}/>
                        <HandySvg onClick={() => {
                            SetSortingQuantity("descending")
                            SetSortingPrice("default")
                        }} src={sorting_down_item} className={styles.arrows_2}/>
                    </div>
                </div>

                <div className={styles.price_filter}>
                    <span className={styles.price_2}>Цена</span>
                    <div className="cursor-pointer">
                        <HandySvg onClick={() => {
                            SetSortingPrice("increase")
                            SetSortingQuantity("default")
                        }} src={sorting_up_item} className={styles.arrows_1}/>
                        <HandySvg onClick={() => {
                            SetSortingPrice("descending")
                            SetSortingQuantity("default")
                        }} src={sorting_down_item} className={styles.arrows_2}/>
                    </div>
                </div>
                <div className={styles.basket}>
                    <div className={styles.sub_filter_basket_wrap} onClick={() => IsAuth ? SetIsOpenBasket(true) : SetIsOpenAuth(true)}>
                        <HandySvg src={basket} className={styles.sub_filter_basket_svg}/>
                        {BasketItems.length ? <div className={styles.sub_filter_basket_count_items_wrap}>
                            <span className={styles.sub_filter_basket_count_items}>{BasketItems.length}</span>
                        </div> : null}
                    </div>
                </div>
            </div>
    </section>
    );
}