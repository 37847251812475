import {HandySvg} from "handy-svg";

import blue_right_arrow from "../../../assets/svg/blue_right_arrow.svg"
import lupa from "../../../assets/svg/lupa.svg"

import styles from "../../../styles/components/SortingAccounts.module.scss";
import {AddNewAccountPopUp} from "./AddNewAccountPopUp";
import {useState} from "react";
import {StoreUser} from "../../../store/store";


export const SortingAccounts = () => {
    const SetFiltersAccounts = StoreUser((state) => state.SetFiltersAccounts)
    const FiltersAccounts = StoreUser((state) => state.FiltersAccounts)
    const [isOpenAddAccount, setIsOpenAddAccount] = useState(false)
    return (
        <section className={styles.tracked_accounts_bg}>
            <section>
                <div className={styles.tracked_accounts}>
                    <div className={styles.sub_tracked_accounts}>
                        <div className={styles.wrap}></div>
                        <div className={styles.sub_tracked_accounts_h2_block}>
                            <h2 className={styles.sub_tracked_accounts_h2}>Отслеживаемые аккаунты</h2>
                        </div>
                        <div className={styles.sub_tracked_accounts_a_block}>
                            <AddNewAccountPopUp isOpenAddAccount={isOpenAddAccount} setIsOpenAddAccount={setIsOpenAddAccount}/>
                            <span className={styles.sub_tracked_accounts_a} onClick={() => setIsOpenAddAccount(true)}>ДОБАВИТЬ АККАУНТ</span>
                            <HandySvg src={blue_right_arrow} className={styles.add_bank_card_svg} onClick={() => setIsOpenAddAccount(true)}/>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className={styles.sub_quantity_filter_frst}>
                    <HandySvg src={lupa} className={styles.sub_quantity_filter_svg}/>
                    <input value={FiltersAccounts}
                           onChange={(e) => SetFiltersAccounts(e.target.value)}
                           type="text"
                           className={styles.sub_quantity_filter_input}
                           placeholder="Steam ID, User name"/>
                </div>
                <div className={styles.filter_dub}>

                    <div  className="lg:flex lg:basis-[10%] 2xl:basis-[13%] 3xl:basis-[10.4%]  4xl:basis-1/12 ">
                        <span className={styles.skin}>PHOTO</span>
                    </div>
                    <div className="lg:flex lg:basis-[17%] 2xl:basis-[13%] 3xl:basis-1/4   4xl:basis-1/3">
                        <span className={styles.name}>NAME</span>
                    </div>
                    <div className={styles.quantity_filter}>
                        <span className={styles.quantity_span}>STEAM ID</span>
                        <div className={styles.sub_quantity_filter_last}>
                            <HandySvg src={lupa} className={styles.sub_quantity_filter_svg}/>
                            <input value={FiltersAccounts}
                                   onChange={(e) => SetFiltersAccounts(e.target.value)}
                                   type="text"
                                   className={styles.sub_quantity_filter_input}
                                   placeholder="Steam ID, User name"/>
                        </div>
                    </div>

                    <div className={styles.price_filter}>
                        <span className={styles.price_2}>Выбор игр для отчета</span>
                    </div>

                    <div className={styles.basket}>
                        <div>
                            <span className={styles.price_2_basket}>СКАЧАТЬ ОТЧЕТ В EXCEL</span>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    )
}