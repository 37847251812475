import styles from "../../../styles/components/FullBasket.module.scss"

import {Items} from "./Items";
import {Right} from "./Right";
import {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {StoreBasket} from "../../../store/store";
import {SuccessOperation} from "./SuccessOperation";
import {ErrorOperation} from "./ErrorOperation";
import {LoaderTrade} from "./LoaderTrade";

export const FullBasketPopUp = () => {
    const SetIsOpen = StoreBasket((state) => state.SetIsOpen)
    const IsOpen = StoreBasket((state) => state.IsOpen)
    return (
        <>
            <Transition appear show={IsOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => SetIsOpen(false)}>
                    <div className="fixed inset-0 overflow-y-auto bg-opacity-40 bg-black">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel>
                                    <FullBasket/>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
            <SuccessOperation/>
            <ErrorOperation/>
            <LoaderTrade/>
        </>
    )
}

export const FullBasket = () => {
    return (
        <div className={styles.main_basket_1}>
            <div className={styles.sub_main_basket}>
                <Items/>
                <Right/>
            </div>
        </div>
    )
}