import styles from "../../../styles/components/SuccessAddedPopUp.module.scss"
import cancel from "../../../assets/svg/cancel_banner.svg"
import success from "../../../assets/svg/success_added.svg"
import {HandySvg} from "handy-svg";
import {Fragment, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
export const SuccessAddedPopUp = ({title, desc}) => {
    const [IsOPen, SetIsOPen] = useState(true)
    return (
        <Transition appear show={IsOPen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => SetIsOPen(false)}>
                <div className="fixed inset-0 overflow-y-auto bg-opacity-40 bg-black">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel>
                                <div className={styles.success_loading}>
                                    <div>
                                        <HandySvg src={cancel} className={styles.cross_3} onClick={() => SetIsOPen(false)}/>
                                        <HandySvg src={success} className={styles.success_svg}/>
                                        <h2 className={styles.success_loading_prgrf}>{title}</h2>
                                        <span className={styles.success_loading_text}>{desc}</span>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}