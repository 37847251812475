import {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import styles from "../../../styles/components/CardRemove.module.scss";
import {HandySvg} from "handy-svg";
import cancel from "../../../assets/svg/cancel_banner.svg";
import {StoreDelAccount, StoreUser} from "../../../store/store";
import {DelAccountAPI} from "../../../services/api";

export const DeleteAccount = () => {
    const SetIsOpen = StoreDelAccount((state) => state.SetIsOpen)
    const steamId = StoreDelAccount((state) => state.steamId)
    const isOpen = StoreDelAccount((state) => state.isOpen)
    const SetAccounts = StoreUser((state) => state.SetAccounts)
    const removeAccount = async () => {
        const accounts = await DelAccountAPI(steamId)
        SetAccounts(accounts.accounts)
        SetIsOpen(false)
    }
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => SetIsOpen(false)}>
                <div className="fixed inset-0 overflow-y-auto bg-opacity-40 bg-black">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel>
                                <div className={styles.accept_delete}>
                                    <div>
                                        <HandySvg src={cancel} className={styles.cross_3} onClick={() => SetIsOpen(false)}/>
                                        <h2 className={styles.accept_delete_prgrf}>Вы действительно хотите удалить аккаунт?</h2>
                                        <span id="logo" className={styles.login_via_steam} onClick={removeAccount}>Удалить</span>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
