export const BASIC_URL = process.env.NODE_ENV === 'production' ? "https://steaminventory.ru/" : 'http://localhost:8000/'

export const BASIC_URL_API = process.env.NODE_ENV === 'production' ? "https://api.steaminventory.ru/" : 'http://localhost:8000/'
export const TRADE_URL_API = process.env.NODE_ENV === 'production' ? 'https://tf2key.ru/api' : 'http://localhost:8001/'

export const LogoutLink = "logout"
export const RefreshJwtLink = "refresh_jwt"


export const CreateGoogleTokenLink = "create_google_token"
export const CheckGoogleTokenLink = "check_google_token"

export const CurrencyLink = "currency"

export const GetInventoryLink = "get_inventory"
export const GetInventoryDeprecatedLink = "get_inventory_deprecated"

export const ProfileLink = "profile"
export const AddCard = "add_card"
export const GetCardsLink = "get_cards"
export const DelCardLink = "delete_card"
export const BuyLicenseLink = "buy_license"

export const CommissionsLink = "commissions"


export const UserLink = "user"
export const SaveUserLink = "save-user"

export const CreateTradeLink = "create_trade_sell_items"
export const StatusTradeLink = "status_trade_sell_items"
export const CloseTradeLink = "close_trade"

export const CreateInvoiceLink = "create_invoice"

export const GetAccountsLink = "get_accounts"
export const AddAccountLink = "add_account"
export const ReportAccountsLink = "report_accounts"
export const ReportAccountLink = "report_account"

export const ReportAccountMainLink = "report_account_main"

export const DelAccountLink = "remove_account"

export const SaveReportLink = "save_report"
