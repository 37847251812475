import styles from "../../../styles/components/Items.module.scss"

import {BasketItemGrid} from "./BasketItemGrid";
import {TopItems} from "./TopItems";
import {TotalGoods} from "./TotalGoods";
import {EmptyBasket} from "./EmptyBasket";
import {StoreBasket} from "../../../store/store";


export const Items = () => {
    const Items = StoreBasket((state) => state.Items)
    return (
        <div className={styles.sub_main_basket_left}>
            <TopItems/>
            <TotalGoods/>
            {Items.length ? <BasketItemGrid/> : <EmptyBasket/>}
        </div>
    )
}