import styles from "../../../styles/components/BasketItemGrid.module.scss"
import {StoreBasket} from "../../../store/store";
import {GroupingItems} from "../../../utils/inventory";
import {ItemStatic} from "./Item";

export const BasketItemGrid = () => {
    const Items = StoreBasket((state) => state.Items)
    const GroupItems = GroupingItems(Items)
    return (
        <div className={styles.sub_main_basket_left_card}>
            <div className={styles.sub_main_basket_left_sub_card}>
                {Object.entries(GroupItems).map(([k, v]) => (
                    <ItemStatic
                        key={k}
                        Items={GroupItems[k]["Items"]}
                        Price={GroupItems[k]["Skin Sorter"]["prices"]}
                        MarketHashName={GroupItems[k]["market_hash_name"]}
                        Image={GroupItems[k]["img"]}
                        GroupId={GroupItems[k]["group_id"]}
                        BasketItemsIds={GroupItems[k]["Ids"]}
                    />
                ))}
            </div>
        </div>
    )
}