import styles from "../../../styles/components/LoaderTrade.module.scss"
import {StoreBasket} from "../../../store/store";
import {Dialog, Transition} from "@headlessui/react";
import {Fragment, useEffect, useState} from "react";
import {HandySvg} from "handy-svg";
import React from 'react';
import quit from "../../../assets/svg/cancel_banner.svg";
import classNames from "classnames";

export const LoaderTrade = () => {
    const IsOpen = StoreBasket((state) => state.IsOpenLoader);
    // const SetIsOpen = StoreBasket((state) => state.SetIsOpenLoader);

    const [animationStep, setAnimationStep] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            setAnimationStep((prevStep) => (prevStep < 3 ? prevStep + 1 : 0));
        }, 2000);

        return () => clearTimeout(timer);
    }, [animationStep]);

    const textArray = ["Смотрим инвентарь", "Создаем трейд", "Проверяем трейд", "Подтверждаем трейд"];

    return (
        <Transition appear show={IsOpen} as={Fragment}>
            <Dialog as="div" className="relative z-20" onClose={() => null}>
                <div className="fixed inset-0 overflow-y-auto bg-opacity-40 bg-black">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel>
                                <div className={styles.new_account}>
                                    <div className={styles.header_new_account}>
                                        {/*<div onClick={() => SetIsOpen(false)}>*/}
                                        {/*    <HandySvg src={quit} className={styles.cross_3_new_account} />*/}
                                        {/*</div>*/}
                                        <h2 className={styles.prgrf_new_account}>Еще несколько секунд...</h2>
                                    </div>
                                    <div>
                                        <span className={styles.text_new_account}>
                                            {textArray[animationStep]}
                                            <span className={styles.dots}>{Array(animationStep + 2).join(".")}</span>
                                        </span>
                                        <div className={styles.new_account_content_sub}>
                                            {Array.from({ length: 4 }, (_, index) => (
                                                <div
                                                    key={index}
                                                    className={classNames(
                                                        styles.new_account_content_sub_block,
                                                        {
                                                            [styles.new_account_content_sub_block_active]: index < animationStep,
                                                            [styles.new_account_content_sub_block_pre_active]: index === animationStep,
                                                            [styles.new_account_content_sub_block_deactivated]: index > animationStep,
                                                            [styles.new_account_content_sub_block_loop]: index === 3 && animationStep === 0,
                                                        }
                                                    )}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

