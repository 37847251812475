import styles from "../../../styles/components/Cards.module.scss"
import classNames from "classnames";
import open_select from "../../../assets/svg/open_select_user.svg"
import {HandySvg} from 'handy-svg';
import {useState} from "react";
import {StoreUser} from "../../../store/store";


export const SelectCard = () => {
    const [IsOpenBankList, SetIsOpenBankList] = useState(false)
    const LastActiveBankCard = StoreUser((state) => state.LastActiveBankCard)

   return (
       <div className={styles.choice_card}>
           <div>
               <div className={styles.sub_choice_card_1_block}>
                   <input readOnly={true} type="text" onClick={() => SetIsOpenBankList(!IsOpenBankList)}
                          className={styles.qiwi_wallet_number_input} value={LastActiveBankCard}/>
                   {IsOpenBankList ?
                       <HandySvg src={open_select} onClick={() => SetIsOpenBankList(false)} className={classNames(styles.sub_choice_card_1_block_svg, "rotate-180")}/> :
                       <HandySvg src={open_select} onClick={() => SetIsOpenBankList(true)} className={styles.sub_choice_card_1_block_svg}/>}
               </div>
               {IsOpenBankList ? <RenderCards/> : null}
           </div>

       </div>
   )
}

export const RenderCards = () => {
    const BankCard = StoreUser((state) => state.BankCard)
    return (
        <div className={classNames(styles.sub_choice_card_2, styles.qiwi_wallet_number_span)}>
            {BankCard.map((number, key) =>
                <Card CardId={number} key={key}/>
            )}
        </div>
    )
}

export const Card = ({CardId}) => {
    const SetLastActiveBankCard = StoreUser((state) => state.SetLastActiveBankCard)
    const LastActiveBankCard = StoreUser((state) => state.LastActiveBankCard)
    return (
        <div className={styles.sub_choice_card_2_block} onClick={() => SetLastActiveBankCard(CardId)}>
            <span className={CardId === LastActiveBankCard ? styles.card_active : styles.card_deactive}>{CardId}</span>
        </div>
    )
}