import ky from "ky"
import {BASIC_URL_API, TRADE_URL_API} from "./api_constants"

export const Api = ky.create(
    {prefixUrl: BASIC_URL_API, timeout: 120000, credentials: 'include'}
)

export const SecureApi = Api.extend({
    credentials: 'include'
})


export const TradeApi = ky.create({prefixUrl: TRADE_URL_API, timeout: 120000, credentials: 'omit'})