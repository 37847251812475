import {HandySvg} from 'handy-svg';

import styles from "../../../styles/components/CardRemove.module.scss"

import cancel from "../../../assets/svg/cancel_banner.svg"
import {Fragment, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {DelCard} from "../../../services/api";
import {StoreUser} from "../../../store/store";
export const CardRemove = (props) => {
    const SetLastActiveBankCard = StoreUser((state) => state.SetLastActiveBankCard)
    const LastActiveBankCard = StoreUser((state) => state.LastActiveBankCard)
    const SetBankCard = StoreUser((state) => state.SetBankCard)
    const BankCard = StoreUser((state) => state.BankCard)
    const [isOpen, setIsOpen] = useState(true)
    const ClosePopUp = () => {
        props.SetIsOpenBankPopUp(false)
        setIsOpen(false)
    }
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => ClosePopUp()}>
                <div className="fixed inset-0 overflow-y-auto bg-opacity-40 bg-black">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel>
                                <div className={styles.accept_delete}>
                                    <div>
                                        <HandySvg src={cancel} className={styles.cross_3} onClick={() => ClosePopUp()}/>
                                        <h2 className={styles.accept_delete_prgrf}>Вы действительно хотите
                                            удалить банковскую карту?</h2>
                                        <span id="logo" className={styles.login_via_steam} onClick={async () => {
                                            ClosePopUp()
                                            await DelCard(props.CardId)
                                            const new_cards = BankCard.filter(function(item) {
                                                return item !== props.CardId
                                            })
                                            SetBankCard(new_cards)
                                            SetLastActiveBankCard(new_cards.length ? (LastActiveBankCard === props.CardId ? new_cards[0] : LastActiveBankCard) : "")
                                        }}>Удалить</span>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}