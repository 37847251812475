import { create } from 'zustand'
import {GetPlaceHolderProfile} from "../utils/inventory";
import {Commission, GetAccounts, GetCards, GetCurrency, GetUserData} from "../services/api";
import {getTradeOfferStateData} from "../utils/Trade";

export const StoreLoadInventory = create((set) => ({
    Items: [],
    PriceInventory: 0,
    UserName: "@Loading...",
    UserImage: null,
    InventoryId: "",

    SortingQuantity: "default",
    SortingPrice: "descending",

    GridScrollStart: 0,
    GridStep: 36,

    AloneOrGroup: "Group",
    GridRow: "Grid",
    Marketrable: "Marketrable",
    Tradable: "All",

    Game: "CS:GO",
    TradeLink: "",
    Currency: "RUB",

    ErrorLoadingPopUp: false,
    ActiveSource: "Steam",

    ErrorText: null,

    SetItems: (data) => set((state) => ({ Items: data })),

    SetSortingQuantity: (data) => set((state) => ({ SortingQuantity: data })),
    SetActiveSource: (data) => set((state) => ({ ActiveSource: data })),
    SetSortingPrice: (data) => set((state) => ({ SortingPrice: data })),

    SetInventoryId: (data) => set((state) => ({ InventoryId: data })),
    SetUserImage: (data) => set((state) => ({ UserImage: data })),
    SetUserName: (data) => set((state) => ({ UserName: data })),

    SetSelectGame: (data) => set((state) => ({ Game: data })),
    SetTradeLink: (data) => set((state) => ({ TradeLink: data })),
    SetCurrency: (data) => set((state) => ({ Currency: data })),

    SetGridScrollStart: (data) => set((state) => ({ GridScrollStart: data })),
    SetGridStep: (data) => set((state) => ({ GridStep: data })),

    SetAloneOrGroup: (data) => set((state) => ({ AloneOrGroup: data })),
    SetGridRow: (data) => set((state) => ({ GridRow: data })),
    SetMarketrable: (data) => set((state) => ({ Marketrable: data })),
    SetTradable: (data) => set((state) => ({ Tradable: data })),

    SetErrorLoadingPopUp: (data) => set((state) => ({ ErrorLoadingPopUp: data })),
    SetErrorText: (data) => set((state) => ({ ErrorText: data })),
    ResetLoadInventory: (data) => set((state) => ({
        Items: [],
        PriceInventory: 0,
        UserName: null,
        UserImage: null,
        InventoryId: null,

        ErrorLoadingPopUp: false,
    })),
    ResetChangeGame: (data) => set((state) => ({
        Items: [],
        PriceInventory: 0,

        ErrorLoadingPopUp: false,
    })),
}))


export const StoreCurrency = create((set) => ({
    Currency: {
        "RUB": {
            "name": "RUB",
            "name_usd": 1,
            "value": 1
        },
        "USD": {
            "name": "USD",
            "name_usd": 1,
            "value": 1
        },
        "EURO": {
            "name": "EURO",
            "name_usd": 1,
            "value": 1
        },
        "YUAN": {
            "name": "CNY",
            "name_usd": 1,
            "value": 1
        },
        "TENGE": {
            "name": "KZT",
            "name_usd": 1,
            "value": 1
        },
        "BEL RUB": {
            "name": "BYN",
            "name_usd": 1,
            "value": 1
        }
    },
    Loaded: null,
    SetCurrency: (data) => set((state) => ({ Currency: data })),
    FetchCurrency: async function () {
        const res_currency = await GetCurrency();
        set({Currency: res_currency["res"],
            Loaded: true})
    }
}))

export const StoreUser = create((set) => ({
    IsAuth: false,
    TradeLink: null,
    SteamData: null,
    Limits: null,
    Balance: null,
    Registration: null,

    Telegram: null,
    Email: null,
    BankCard: null,
    Qiwi: null,
    Trc20: null,
    LastActiveBankCard: null,
    Accounts: null,
    FiltersAccounts: "",
    isOpenSelectAccount: false,

    SetIsOpenSelectAccount: (data) => set((state) => ({ isOpenSelectAccount: data })),
    SetFiltersAccounts: (data) => set((state) => ({ FiltersAccounts: data })),
    SetBalance: (data) => set((state) => ({ Balance: data })),
    SetLimits: (data) => set((state) => ({ Limits: data })),
    SetAccounts: (data) => set((state) => ({ Accounts: data })),
    AddAccountAct: (data) => set((state) => ({ Accounts: [data, ...state.Accounts] })),
    SetTradeLink: (data) => set((state) => ({ TradeLink: data })),
    SetSteamData: (data, key) => set((state) => ({ ...state, SteamData: { [key]: data, ...state.SteamData } })),
    SetTelegram: (data) => set((state) => ({ Telegram: data })),
    SetEmail: (data) => set((state) => ({ Email: data })),
    SetBankCard: (data) => set((state) => ({ BankCard: data })),
    SetQiwi: (data) => set((state) => ({ Qiwi: data })),
    SetTrc20: (data) => set((state) => ({ Trc20: data })),
    SetLastActiveBankCard: (data) => set((state) => ({ LastActiveBankCard: data })),
    Reset: (data) => set((state) => ({
        IsAuth: false,
        SteamData: null,
        Limits: null,
        Balance: null,
        Registration: null,

        Telegram: null,
        Email: null,
        BankCard: null,
        Qiwi: null,
        Trc20: null,
        LastActiveBankCard: null,
        Accounts: null,
        FiltersAccounts: "",
    })),
    FetchUser: async function () {
        const res_user = await GetUserData();
        set({
            SteamData: res_user["steam"],
            TradeLink:res_user["steam"]['trade link'],
            Limits: res_user["limits"],
            Balance: res_user["balance"],
            Registration: res_user["registration"],
            Telegram: res_user["telegram"],
            Email: res_user["email"],
            Qiwi: res_user["qiwi"],
            Trc20: res_user["trc20"],
            IsAuth: res_user["auth"],
        });
    },
    FetchAccounts: async function () {
        const res_accounts = await GetAccounts();
        set({
            Accounts: res_accounts["accounts"]
        })
    },
    FetchCards: async function () {
        const res_cards = await GetCards();
        set({
            BankCard: res_cards["bankCards"],
            LastActiveBankCard: res_cards["last active bank card"]
        })
    }
}))

export const StoreBasket = create((set) => ({
    IsOpen: false,
    PaymentType: "qiwi",

    Step: false,
    Status: getTradeOfferStateData(2),

    IsOpenError: false,
    ErrorText: null,
    IsOpenSuccess: false,
    IsOpenLoader: false,

    Qiwi: "",
    Trc20: "",
    Email: "",
    LastActiveBankCard: null,

    Items: [],
    TradeId: null,
    TradeIdInner: null,
    tradeLink: "",

    SetIsOpen: (data) => set((state) => ({ IsOpen: data })),
    SetPaymentType: (data) => set((state) => ({ PaymentType: data })),

    SetTradeId: (data) => set((state) => ({ TradeId: data })),
    SetTradeIdInner: (data) => set((state) => ({ TradeIdInner: data })),

    SetStep: (data) => set((state) => ({ Step: data })),
    SetStatus: (data) => set((state) => ({ Status: data })),

    SetErrorText: (data) => set((state) => ({ ErrorText: data })),
    SetIsOpenError: (data) => set((state) => ({ IsOpenError: data })),
    SetIsOpenSuccess: (data) => set((state) => ({ IsOpenSuccess: data })),
    SetIsOpenLoader: (data) => set((state) => ({ IsOpenLoader: data })),

    SetQiwi: (data) => set((state) => ({ Qiwi: data })),
    SetTrc20: (data) => set((state) => ({ Trc20: data })),
    SetEmail: (data) => set((state) => ({ Email: data })),
    SetLastActiveBankCard: (data) => set((state) => ({ LastActiveBankCard: data })),
    SetTradeLink: (data) => set((state) => ({ tradeLink: data })),

    AddItem: (data) => set((state) => ({ Items: [data, ...state.Items] })),
    RemoveItem: (Item) => set((state) => ({ Items: Object.values(state.Items).filter(item => item["full id"] !== Item["full id"]) })),
    DeleteAllItems: () => set((state) => ({ Items: [] })),
}))

export const StoreAuthPopup = create((set) => ({
    IsOpen: false,
    SetIsOpen: (data) => set((state) => ({ IsOpen: data })),
}))

export const StoreCommissions = create((set) => ({
    commissions: null,
    card: null,
    qiwi: null,
    usdt: null,
    FetchCommission: async function () {
        const res = await Commission();
        set({
            card: res["Cards"],
            qiwi: res["Qiwi"],
            usdt: res["USDT"],
            commissions: {
                card: res["Cards"],
                qiwi: res["Qiwi"],
                usdt: res["USDT"]
            }
        })
    }
}))

export const StoreDelAccount = create((set) => ({
    steamId: null,
    isOpen: false,
    SetSteamId: (data) => set((state) => ({ steamId: data })),
    SetIsOpen:(data) => set((state) => ({ isOpen: data })),
}))

export const StoreSelectLicense = create((set) => ({
    lvl: null,
    isOpen: false,
    SetLVL: (data) => set((state) => ({ lvl: data })),
    SetIsOpen:(data) => set((state) => ({ isOpen: data })),
}))