import styles from "../../styles/components/GameBlock.module.scss"

import all_games_mobile from "../../assets/img/all_games.webp"
import all_games_desktop from "../../assets/img/all_games_desktop.webp"
import cs_go from "../../assets/img/cs_go.webp"
import dota from "../../assets/img/dota.webp"
import rust from "../../assets/img/rust.webp"
import tf from "../../assets/img/tf.webp"

import all_games_mobile_active from "../../assets/img/all_games_active.webp"
import all_games_desktop_active from "../../assets/img/all_games_desktop_active.webp"
import cs_go_active from "../../assets/img/cs_go_active.webp"
import dota_active from "../../assets/img/dota_active.webp"
import rust_active from "../../assets/img/rust_active.webp"
import tf_active from "../../assets/img/tf_active.webp"

import {StoreLoadInventory} from "../../store/store";
export const GameBlock = () => {
    const ActiveGame = StoreLoadInventory((state) => state.Game)
    const SetActiveGame = StoreLoadInventory((state) => state.SetSelectGame)
    const ResetChangeGame = StoreLoadInventory((state) => state.ResetChangeGame)
    return (
        <section>
            <div className={styles.game_block}>
                <div className={styles.sub_game_block}>
                    <div className={styles.sub_game_block_allGames}>
                        <img alt="AllGamesDesktop" src={ActiveGame === "AllGamesDesktop" ? all_games_desktop_active : all_games_desktop} className={styles.all_games_1}  onClick={() => SetActiveGame("AllGamesDesktop")}/>
                        <img alt="AllGamesMobile" src={ActiveGame === "AllGamesMobile" ? all_games_mobile_active : all_games_mobile} className={styles.all_games_2}  onClick={() => SetActiveGame("AllGamesMobile")}/>
                        <span className={styles.sub_game_block_allGames_span} onClick={() => ActiveGame === "AllGamesMobile" ? SetActiveGame("AllGamesMobile") : SetActiveGame("AllGamesDesktop")}>Все игры</span>
                    </div>
                    <div onClick={() => {SetActiveGame("CS:GO");ResetChangeGame()}}>
                        <img src={ActiveGame === "CS:GO" ? cs_go_active : cs_go} className={styles.Game} alt="CS:GO"/>
                    </div>
                </div>
                <div className={styles.sub_game_block_Dota}>
                    <div onClick={() => {SetActiveGame("Dota");ResetChangeGame()}}>
                        <img src={ActiveGame === "Dota" ? dota_active : dota} className={styles.Game} alt="Dota"/>
                    </div>
                    <div onClick={() => {SetActiveGame("Rust");ResetChangeGame()}}>
                        <img src={ActiveGame === "Rust" ? rust_active : rust} className={styles.Game} alt="Rust"/>
                    </div>
                </div>
                <div onClick={() => {SetActiveGame("TF");ResetChangeGame()}}>
                    <img src={ActiveGame === "TF" ? tf_active : tf} className={styles.Game} alt="TF"/>
                </div>
            </div>
        </section>
    );
}