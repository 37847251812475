import styles from "../../../styles/components/AccountRow.module.scss"

import {HandySvg} from "handy-svg";

import all_games from "../../../assets/img/all_games_account.webp"
import all_games_active from "../../../assets/img/all_games_account_active.webp"
import csgo from "../../../assets/img/cs_go_account.webp"
import csgo_active from "../../../assets/img/cs_go_account_active.webp"
import dota from "../../../assets/img/dota_account.webp"
import dota_active from "../../../assets/img/dota_account_active.webp"
import rust from "../../../assets/img/rust_account.webp"
import rust_active from "../../../assets/img/rust_account_active.webp"
import tf from "../../../assets/img/tf_account.webp"
import tf_active from "../../../assets/img/tf_account_active.webp"
import {useEffect, useState} from "react";
import {StoreDelAccount, StoreLoadInventory} from "../../../store/store";
import {Link} from "react-router-dom";
import {GetAppIdByName, LoadInventory, LoadProfile} from "../../../utils/inventory";
import {BASIC_URL_API, ReportAccountLink} from "../../../services/api_constants";
import cancel from "../../../assets/svg/cancel_banner.svg";
import {DeleteAccount} from "./DeleteAccount";
import {LoadingInventoryPopUp} from "../../modules/LoadingInventory";

export const AccountRow = (props) => {
    const ActiveGame = StoreLoadInventory((state) => state.Game)
    const SetActiveGame = StoreLoadInventory((state) => state.SetSelectGame)
    const [ActiveGameAccount, SetActiveGameAccount] = useState(ActiveGame.replace("Mobile", "").replace("Desktop", ""))
    const SetErrorLoadingPopUp = StoreLoadInventory((state) => state.SetErrorLoadingPopUp)
    const SetItems = StoreLoadInventory((state) => state.SetItems)
    const SetUserName = StoreLoadInventory((state) => state.SetUserName)
    const SetUserImage = StoreLoadInventory((state) => state.SetUserImage)
    const SetInventoryId = StoreLoadInventory((state) => state.SetInventoryId)
    const SetUserLink = StoreLoadInventory((state) => state.SetTradeLink)
    const SetIsOpen = StoreDelAccount((state) => state.SetIsOpen)
    const SetSteamId = StoreDelAccount((state) => state.SetSteamId)
    const SetErrorText = StoreLoadInventory((state) => state.SetErrorText)
    const [isOpenLoading, setIsOpenLoading] = useState(false)


    useEffect(() => {
        SetActiveGameAccount(ActiveGame.replace("Mobile", "").replace("Desktop", ""))
    }, [ActiveGame])

    const OnLoadInventory = async () => {
        SetUserLink(props.item.profileurl)
        SetActiveGame(ActiveGameAccount)
        await Promise.all([
            LoadInventory(props.item.profileurl, ActiveGame, SetErrorLoadingPopUp, SetItems, SetErrorText),
            LoadProfile(props.item.profileurl, SetErrorLoadingPopUp, SetUserName, SetUserImage, SetInventoryId)
        ]);
    };
    return (
        <>
            <LoadingInventoryPopUp isOpen={isOpenLoading} setIsOpen={setIsOpenLoading} text={"Процесс создания отчета займет 1-5 мин."}/>
            <DeleteAccount/>
            <div className={styles.card}>
                <div className={styles.sub_card}>
                    <div className={styles.bg_steel} onClick={() => window.open(props.item.profileurl, '_blank').focus()}>
                        <img src={props.item.avatarfull} className={styles.bg_steel_img} alt={props.item.personaname}/>
                    </div>
                    <div className={styles.content_card}>
                        <div className={styles.prgrf_card_block}>
                            <span className={styles.prgrf_card}>{props.item.personaname}</span>
                        </div>
                        <div className={styles.content_card_span_1_block}>
                            <span className={styles.content_card_span_1}>{props.item.steamid}</span>
                        </div>
                        <div className={styles.content_card_img}>
                            <img src={ActiveGameAccount === "AllGames" ? all_games_active : all_games } onClick={() => SetActiveGameAccount("AllGames")} alt="all"/>
                            <img src={ActiveGameAccount === "CS:GO" ? csgo_active : csgo} onClick={() => SetActiveGameAccount("CS:GO")} alt="cs"/>
                            <img src={ActiveGameAccount === "Dota" ? dota_active : dota} onClick={() => SetActiveGameAccount("Dota")} alt="dota"/>
                            <img src={ActiveGameAccount === "Rust" ? rust_active : rust} onClick={() => SetActiveGameAccount("Rust")} alt="rust"/>
                            <img src={ActiveGameAccount === "TF" ? tf_active : tf} onClick={() => SetActiveGameAccount("TF")} alt="tf"/>
                        </div>


                        <div className={styles.card_price}>
                            <Link to="/" onClick={OnLoadInventory}>
                            <span id="logo" onClick={OnLoadInventory}
                                  className={styles.card_price_button_db}>
                                ОСМОТРЕТЬ
                            </span>
                            </Link>
                            <div onClick={() => setIsOpenLoading(true)}>
                                <a id="logo"
                                   href={`${BASIC_URL_API}${ReportAccountLink}?steamid=${props.item.steamid}&app_id=${GetAppIdByName[ActiveGameAccount]}`}
                                   download={`${props.item.personaname}___${GetAppIdByName[ActiveGameAccount]}.xlsx`}
                                   className={styles.card_price_button_db}
                                >
                                    СКАЧАТЬ
                                </a>
                            </div>

                        </div>
                        {props.item.main ? null : <div className={styles.remove_account}>
                            <HandySvg src={cancel} className={styles.cross_3} onClick={() => {SetIsOpen(true); SetSteamId(props.item.steamid)}}/>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}