import {SelectPaymentMethod} from "./SelectPaymentMethod";
import {HeaderRight} from "./HeaderRight";
import {FooterRight} from "./FooterRight";
import {FormWallet} from "./FormWallet";

import styles from "../../../styles/components/Right.module.scss"




export const Right = () => {
    return (
        <div className={styles.sub_main_basket_right}>
            <HeaderRight/>
            <SelectPaymentMethod/>
            <FormWallet/>
            <FooterRight/>
        </div>
    )
}