import styles from "../../../styles/components/HeaderRight.module.scss"

import {HandySvg} from "handy-svg";
import cancel_popup from "../../../assets/svg/cancel_banner.svg";
import {StoreBasket, StoreCurrency} from "../../../store/store";

export const HeaderRight = () => {
    const Items = StoreBasket((state) => state.Items)
    const sum_items = Items.reduce((accumulator, currentValue) => accumulator + currentValue["Skin Sorter"]["prices"]["median"], 0);
    const Currency = StoreCurrency((state) => state.Currency)
    const SetIsOpenBasket = StoreBasket((state) => state.SetIsOpen)
    return (
        <>
            <div className={styles.header_sub_main_basket_right}>
                <div className={styles.header_sub_main_basket_right_doubleSpan}>
                    <span className={styles.header_sub_main_basket_right_span1}>Общая сумма:</span>
                    <div className={styles.header_sub_main_basket_right_span2}>
                        <span className={styles.header_sub_main_basket_right_span3}>₽</span>
                        <span className={styles.header_sub_main_basket_right_span4}>{(sum_items * Currency["RUB"]["name_usd"]).toFixed(2)}</span>
                    </div>
                </div>
                <HandySvg src={cancel_popup} className={styles.header_sub_main_basket_right_svgMobile} onClick={() => SetIsOpenBasket(false)}/>
            </div>
            <div className={styles.sub_main_basket_right_warning}>
                <span className={styles.sub_main_basket_right_warning_span}>Итоговая сумма может отличаться в зависимости от способа вывода средств.</span>
            </div>
        </>
    )
}