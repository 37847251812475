import {HandySvg} from 'handy-svg';
import styles from "../../../styles/components/FilterBasket.module.scss"
import basket from "../../../assets/svg/basket.svg"
import {StoreAuthPopup, StoreBasket, StoreLoadInventory, StoreUser} from "../../../store/store";
import sorting_up_item from "../../../assets/svg/sorting_up_items.svg";
import sorting_down_item from "../../../assets/svg/sorting_down_items.svg";
export const FilterBasket = () => {
    const SetSortingQuantity = StoreLoadInventory((state) => state.SetSortingQuantity)
    const SetSortingPrice = StoreLoadInventory((state) => state.SetSortingPrice)
    const BasketItems = StoreBasket((state) => state.Items)
    const IsAuth = StoreUser((state) => state.IsAuth)
    const SetIsOpenBasket = StoreBasket((state) => state.SetIsOpen)
    const SetIsOpenAuth = StoreAuthPopup((state) => state.SetIsOpen)
    return (
        <section>
            <div className={styles.filter_basket}>
                <div className={styles.deskop_filter_basket}>
                    <div className={styles.sub_filter_basket}>
                        <span className={styles.sub_filter_basket_span_1}>Количество</span>
                        <div className={styles.svg_filter_basket_2}>
                            <HandySvg onClick={() => {
                                SetSortingQuantity("increase")
                                SetSortingPrice("default")
                            }} src={sorting_up_item} className={styles.arrows_1}/>
                            <HandySvg onClick={() => {
                                SetSortingQuantity("descending")
                                SetSortingPrice("default")
                            }} src={sorting_down_item} className={styles.arrows_2}/>
                        </div>
                    </div>

                    <div className={styles.sub_filter_basket}>
                        <span className={styles.sub_filter_basket_span_2}>Цена</span>
                        <div className={styles.svg_filter_basket_1}>
                            <HandySvg onClick={() => {
                                SetSortingPrice("increase")
                                SetSortingQuantity("default")
                            }} src={sorting_up_item} className={styles.arrows_1}/>
                            <HandySvg onClick={() => {
                                SetSortingPrice("descending")
                                SetSortingQuantity("default")
                            }} src={sorting_down_item} className={styles.arrows_2}/>
                        </div>
                    </div>
                </div>
                <div className={styles.basket}>
                    <div className={styles.sub_filter_basket_wrap} onClick={() => IsAuth ? SetIsOpenBasket(true) : SetIsOpenAuth(true)}>
                        <HandySvg src={basket} className={styles.sub_filter_basket_svg}/>
                        {BasketItems.length ? <div className={styles.sub_filter_basket_count_items_wrap}>
                            <span className={styles.sub_filter_basket_count_items}>{BasketItems.length}</span>
                        </div> : null}
                    </div>
                </div>
            </div>

        </section>
    );
}