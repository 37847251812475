import styles from "../../../styles/components/Item.module.scss"
import {HandySvg} from "handy-svg";
import minus from "../../../assets/svg/minus.svg";
import plus from "../../../assets/svg/plus.svg";
import {StoreBasket, StoreCurrency, StoreLoadInventory} from "../../../store/store";
import {FindItemToAdd} from "../../../utils/inventory";
import {useState} from "react";

export const ItemStatic = ({Price, Image, MarketHashName, Items, GroupId, BasketItemsIds}) => {
    const [IsOver, SetIsOver] = useState(false)
    const Currency = StoreCurrency((state) => state.Currency)
    const CountItems = Items.length
    return (
        <div className={styles.sub_main_basket_left_solo_card_addElement} onMouseOver={() => SetIsOver(true)} onMouseLeave={() => SetIsOver(false)}>
            <div className={styles.sub_main_basket_left_solo_card}>
                <img src={Image} className={styles.solo_card_img} alt={MarketHashName}/>
                <div className={styles.solo_card_content_2}>
                    <span className={styles.solo_card_content_span}>{MarketHashName}</span>
                    <div className={styles.sub_solo_card_content}>
                        <span className={styles.sub_main_basket_left_solo_card_span_1}>₽ {(Price.median * Currency["RUB"]["name_usd"]).toFixed(2)}</span>
                        <span className={styles.sub_main_basket_left_solo_card_span_2}>{CountItems === 1 ? null : "х" + CountItems }</span>
                    </div>
                </div>
            </div>
            {IsOver ? <ItemHover Items={Items} GroupId={GroupId} BasketItemsIds={BasketItemsIds}/> : null}
            {IsOver ? <div className="absolute z-10 w-full h-full bg-black top-0 right-0 opacity-50 rounded-lg"></div> : null}
        </div>
    )
}

export const ItemHover = ({Items, GroupId, BasketItemsIds}) => {
    const AllItems = StoreLoadInventory((state) => state.Items)
    const RemoveItem = StoreBasket((state) => state.RemoveItem)
    const AddBasketItem = StoreBasket((state) => state.AddItem)
    const AddItem = (Item) => {
        if (Item){
            AddBasketItem(Item)
        }
    }
    return (
            <div className={styles.sub_main_basket_left_solo_card_add_element}>
                <div className={styles.sub_main_basket_left_solo_card_add_element_block}>
                    <div className={styles.wrap_svg_add_remove_item} onClick={() => RemoveItem(Items[0])}>
                        <HandySvg src={minus} className={styles.sub_main_basket_left_solo_card_add_element_block_svg_1}/>
                    </div>
                    <div className={styles.sub_main_basket_left_solo_card_add_element_block_div}>
                        <span className={styles.sub_main_basket_left_solo_card_add_element_block_div_span}>{Items.length}</span>
                    </div>
                    <div className={styles.wrap_svg_add_remove_item} onClick={() => AddItem(FindItemToAdd(AllItems, GroupId, BasketItemsIds))}>
                        <HandySvg src={plus} className={styles.sub_main_basket_left_solo_card_add_element_block_svg_2}/>
                    </div>
                </div>
            </div>
    )
}
