import styles from "../../../styles/components/ErrorOperation.module.scss"
import error from "../../../assets/svg/error.svg"
import quit from "../../../assets/svg/cancel_banner.svg"
import {HandySvg} from 'handy-svg';
import {Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {StoreBasket} from "../../../store/store";
import {getTradeOfferStateData} from "../../../utils/Trade";


export const ErrorOperation = () => {
    const IsOpen = StoreBasket((state) => state.IsOpenError)
    const SetIsOpen = StoreBasket((state) => state.SetIsOpenError)
    const SetStatus = StoreBasket((state) => state.SetStatus)
    const ErrText = StoreBasket((state) => state.ErrorText)

    const closePopUp = () => {
        SetIsOpen(false)
        SetStatus(getTradeOfferStateData(2))
    }

    return (
        <Transition appear show={IsOpen} as={Fragment}>
            <Dialog as="div" className="relative z-20" onClose={closePopUp }>
                <div className="fixed inset-0 overflow-y-auto bg-opacity-40 bg-black">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel>
                                <div className={styles.error_loading}>
                                    <div>
                                        <div onClick={closePopUp}>
                                            <HandySvg src={quit} className={styles.cross_3}/>
                                        </div>
                                        <HandySvg src={error} className={styles.error_svg}/>
                                        <h2 className={styles.error_loading_prgrf}>Ошибка операции!</h2>
                                        <span className={styles.error_loading_text}>{ErrText ? ErrText : "Приносим свои извинения! Попробуйте снова через некоторое время."}</span>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}