import {HandySvg} from 'handy-svg';
import scroll_deactivate_page from "../../../assets/svg/scroll_deactivate.svg"
import scroll_active_page from "../../../assets/svg/scroll_active.svg"
import styles from "../../../styles/components/ScrollPage.module.scss";
import {StoreLoadInventory} from "../../../store/store";
import {useEffect} from "react";
import {FilterInventory, GroupingItems, SortingItems} from "../../../utils/inventory";

export const ScrollPage = () => {
    const GridScrollStart = StoreLoadInventory((state) => state.GridScrollStart);
    const GridStep = StoreLoadInventory((state) => state.GridStep);
    const SetGridStep = StoreLoadInventory((state) => state.SetGridStep);
    const SetGridScrollStart = StoreLoadInventory((state) => state.SetGridScrollStart);
    const Tradable = StoreLoadInventory((state) => state.Tradable)
    const Marketrable = StoreLoadInventory((state) => state.Marketrable)
    let  Items = StoreLoadInventory((state) => state.Items)
    Items = FilterInventory(Items, Tradable, Marketrable)
    Items = Object.values(GroupingItems(Items))

    useEffect(() => {
        const calculateGridStep = () => {
            let screenWidth = window.innerWidth || document.documentElement.clientWidth;
            let zoom = 1;
            if (screenWidth >= 2560) {
                zoom = 90;
            } else if (screenWidth >= 1871) {
                zoom = 75;
            }
            screenWidth = window.innerWidth * 6;
            const cardWidth = zoom === 90 ? 243 : 240;
            const calculatedGridStep = Math.floor(screenWidth / cardWidth);
            SetGridStep(calculatedGridStep);
        };

        calculateGridStep();

        window.addEventListener('resize', calculateGridStep);

        return () => {
            window.removeEventListener('resize', calculateGridStep);
        };
    }, []);

    const ScrollLeft = () => {
        const Page = GridScrollStart - GridStep;
        if (Page >= 0) {
            SetGridScrollStart(Page);
        }
    };

    const ScrollRight = () => {
        const Page = GridScrollStart + GridStep;
        if (Page <= Items.length) {
            SetGridScrollStart(Page);
        }
    };

    const Scroll = (start, step) => {
        SetGridScrollStart(start);
    };

    const renderSwitchSpans = () => {
        const totalPages = Math.ceil(Items.length / GridStep);
        const currentPage = Math.floor(GridScrollStart / GridStep) + 1;
        const switchSpans = [];

        if (totalPages <= 7) {
            for (let i = 1; i <= totalPages; i++) {
                const start = (i - 1) * GridStep;
                const isActive = start === GridScrollStart;

                const switchSpanClass = isActive ? styles.sub_switch_span_active : styles.sub_switch_span_deactivate;

                switchSpans.push(
                    <span
                        key={i}
                        className={switchSpanClass}
                        onClick={() => Scroll(start, GridStep)}
                    >
                        {i}
                    </span>
                );
            }
        } else {
            if (currentPage <= 4) {
                for (let i = 1; i <= 5; i++) {
                    const start = (i - 1) * GridStep;
                    const isActive = start === GridScrollStart;

                    const switchSpanClass = isActive ? styles.sub_switch_span_active : styles.sub_switch_span_deactivate;

                    switchSpans.push(
                        <span
                            key={i}
                            className={switchSpanClass}
                            onClick={() => Scroll(start, GridStep)}
                        >
                            {i}
                        </span>
                    );
                }
                switchSpans.push(<span key="ellipsis" className={styles.sub_switch_span_deactivate} onClick={() => Scroll(5 * GridStep, GridStep)}>...</span>);
                switchSpans.push(
                    <span
                        key={totalPages}
                        className={styles.sub_switch_span_deactivate}
                        onClick={() => Scroll((totalPages - 1) * GridStep, GridStep)}
                    >
                        {totalPages}
                    </span>
                );
            } else if (currentPage >= totalPages - 3) {
                switchSpans.push(
                    <span
                        key={1}
                        className={styles.sub_switch_span_deactivate}
                        onClick={() => Scroll(0, GridStep)}
                    >
                        1
                    </span>
                );
                switchSpans.push(<span key="ellipsis" className={styles.sub_switch_span_deactivate} onClick={() => Scroll((currentPage - 2) * GridStep, GridStep)}>...</span>);
                for (let i = totalPages - 4; i <= totalPages; i++) {
                    const start = (i - 1) * GridStep;
                    const isActive = start === GridScrollStart;

                    const switchSpanClass = isActive ? styles.sub_switch_span_active : styles.sub_switch_span_deactivate;

                    switchSpans.push(
                        <span
                            key={i}
                            className={switchSpanClass}
                            onClick={() => Scroll(start, GridStep)}
                        >
                            {i}
                        </span>
                    );
                }
            } else {
                switchSpans.push(
                    <span
                        key={1}
                        className={styles.sub_switch_span_deactivate}
                        onClick={() => Scroll(0, GridStep)}
                    >
                        1
                    </span>
                );
                switchSpans.push(<span key="ellipsis1" className={styles.sub_switch_span_deactivate} onClick={() => Scroll((currentPage - 2) * GridStep, GridStep)}>...</span>);
                for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                    const start = (i - 1) * GridStep;
                    const isActive = start === GridScrollStart;

                    const switchSpanClass = isActive ? styles.sub_switch_span_active : styles.sub_switch_span_deactivate;

                    switchSpans.push(
                        <span
                            key={i}
                            className={switchSpanClass}
                            onClick={() => Scroll(start, GridStep)}
                        >
                            {i}
                        </span>
                    );
                }
                switchSpans.push(<span key="ellipsis2" className={styles.sub_switch_span_deactivate} onClick={() => Scroll((currentPage + 1) * GridStep, GridStep)}>...</span>);
                switchSpans.push(
                    <span
                        key={totalPages}
                        className={styles.sub_switch_span_deactivate}
                        onClick={() => Scroll((totalPages - 1) * GridStep, GridStep)}
                    >
                        {totalPages}
                    </span>
                );
            }
        }

        return switchSpans;
    };

    return (
        <div className={styles.switch}>
            <div className={styles.sub_switch}>
                {GridScrollStart > 0 ? (
                    <HandySvg
                        src={scroll_active_page}
                        className={styles.sub_switch_svg_left}
                        onClick={ScrollLeft}
                    />
                ) : (
                    <HandySvg
                        src={scroll_deactivate_page}
                        className={styles.sub_switch_svg_left}
                    />
                )}
                {renderSwitchSpans()}
                {GridScrollStart + GridStep < Items.length ? (
                    <HandySvg
                        src={scroll_active_page}
                        className={styles.sub_switch_svg_right}
                        onClick={ScrollRight}
                    />
                ) : (
                    <HandySvg
                        src={scroll_deactivate_page}
                        className={styles.sub_switch_svg_right}
                    />
                )}
            </div>
        </div>
    );
};



