import styles from "../../../styles/components/FormWallet.module.scss"
import {StoreBasket, StoreUser} from "../../../store/store";
import {GetFullTypoWallet} from "../../../utils/personalData";
import {useEffect} from "react";
import {SelectCard} from "./Cards";

export const FormWallet = () => {

    const UserQiwi = StoreUser((state) => state.Qiwi)
    const UserTrc20 = StoreUser((state) => state.Trc20)
    const UserEmail = StoreUser((state) => state.Email)
    const UserLastActiveBankCard = StoreUser((state) => state.LastActiveBankCard)

    const PaymentType = StoreBasket((state) => state.PaymentType)

    const BasketQiwi = StoreBasket((state) => state.Qiwi)
    const BasketTrc20 = StoreBasket((state) => state.Trc20)
    const BasketEmail = StoreBasket((state) => state.Email)
    const TradeLinkUser = StoreUser((state) => state.TradeLink)
    const TradeLinkBasket = StoreBasket((state) => state.tradeLink)

    const SetBasketQiwi = StoreBasket((state) => state.SetQiwi)
    const SetBasketTrc20 = StoreBasket((state) => state.SetTrc20)
    const SetBasketEmail = StoreBasket((state) => state.SetEmail)
    const SetBasketLastActiveBankCard = StoreBasket((state) => state.SetLastActiveBankCard)
    const SetTradeLinkBasket = StoreBasket((state) => state.SetTradeLink)
    let PlaceHolderPayment
    let ValuePayment
    let start

    const validateSteamTradeLink = (link) => {
        const regex = /^https:\/\/steamcommunity.com\/tradeoffer\/new\/\?partner=\d+&token=[A-Za-z0-9_-]+$/;
        return regex.test(link);
    };

    useEffect(() => {
        if (validateSteamTradeLink(TradeLinkUser)){
            console.log(TradeLinkUser)
            SetTradeLinkBasket(TradeLinkUser);
        }
    }, [start])


    switch (PaymentType) {
        case "qiwi":
            PlaceHolderPayment = UserQiwi
            ValuePayment = BasketQiwi
            break
        case "usdt":
            PlaceHolderPayment = UserTrc20
            ValuePayment = BasketTrc20
            break
        default:
            break
    }

    const SetWallet = (value) => {
        switch (PaymentType) {
            case "qiwi":
                SetBasketQiwi(value)
                break
            case "usdt":
                SetBasketTrc20(value)
                break
            default:
                break
        }
    }


    useEffect(() => {
        SetBasketQiwi(UserQiwi)
        SetBasketTrc20(UserTrc20)
        SetBasketEmail(UserEmail)
        SetBasketLastActiveBankCard(UserLastActiveBankCard)
    }, [])

    return (
        <>
            {PaymentType === "card" ?
                <div className={styles.qiwi_wallet_number}>
                    <span className={styles.qiwi_wallet_number_span_1}>Выберите карту</span><br/>
                    <SelectCard/>
                    <span className={styles.card_wallet_number_span_2}>
                            Если у вас еще не привязана карта, то вы можете добавить карту в личном кабинете в разделе “Платежные реквизиты”.
                    </span>
                </div>
                :
                <div className={styles.qiwi_wallet_number}>
                    <span className={styles.qiwi_wallet_number_span_1}>Ваш номер {GetFullTypoWallet[PaymentType]} кошелька</span><br/>
                    <input type="text" placeholder={PlaceHolderPayment} className={styles.qiwi_wallet_number_input} value={ValuePayment} onChange={(e) => SetWallet(e.target.value)}/>
                </div>}
            <div className={styles.qiwi_wallet_number}>
                <span className={styles.qiwi_wallet_number_span_1}>Ваша трейд <a target="_blank" href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url" style={{color: "#4EC75A"}}>ссылка</a></span><br/>
                <input type="text" placeholder={PlaceHolderPayment} className={styles.qiwi_wallet_number_input} value={TradeLinkBasket} onChange={(e) => SetTradeLinkBasket(e.target.value)}/>
                <div style={{padding: "0 0 80px 0"}}></div>
            </div>
            {/*<div className={styles.information_on_email}>*/}
            {/*        <span className={styles.information_on_email_span_1}>*/}
            {/*            Вся информация о произведенной операции придет на ваш E-mail.*/}
            {/*        </span>*/}
            {/*    <input type="email" placeholder={BasketEmail} value={BasketEmail} onChange={(event) => SetBasketEmail(event.target.value)} className={styles.qiwi_wallet_number_input}/>*/}
            {/*    <span className={styles.information_on_email_span_2}>*/}
            {/*            Если вы еще не указали свой E-mail, то вы можете добавить его в Личном кабинете в разделе “Общая информация основного аккаунта”.*/}
            {/*        </span>*/}
            {/*</div>*/}
        </>
    )
}