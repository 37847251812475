import styles from "../../styles/components/HeaderNav.module.scss"
import {Logout} from "../../services/api";
import {Link, useLocation} from "react-router-dom";
import {StoreUser} from "../../store/store";
export const HeaderNav = (props) => {
    const location = useLocation();
    const Reset = StoreUser((state) => state.Reset)
    return (
        <div className={styles.block_currency} onMouseLeave={() => props.onMouseLeave()}>
            <div className={styles.content_block_currency}>
                <Link to="/">
                    <span className={location.pathname === "/" ? styles.rub_currency : styles.any_currency}>Калькулятор</span>
                </Link>
                <Link to="/profile">
                    <span className={location.pathname === "/profile" ? styles.rub_currency : styles.any_currency}>Личный кабинет</span>
                </Link>
                <Link to="/" className={styles.any_currency} onClick={
                    async  () => {
                        await Logout();
                        Reset()
                    }
                }>Выйти</Link>
            </div>
        </div>
    )
}