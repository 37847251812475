import {Header} from "./components/modules/Header";
import {Footer} from "./components/modules/Footer";
import {PersonalArea} from "./pages/PersonalArea";
import {StoreCommissions, StoreCurrency, StoreUser} from "./store/store";
import {useEffect} from "react";
import {FullBasketPopUp} from "./components/common/payment/FullBasket";
import {Main} from "./pages/Main";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import {Politic} from "./pages/Politic";
import {Error404} from "./pages/Error404";


function App() {
    const IsAuth = StoreUser((state) => state.IsAuth)
    const FetchUser = StoreUser((state) => state.FetchUser)
    const FetchAccounts = StoreUser((state) => state.FetchAccounts)
    const FetchCards = StoreUser((state) => state.FetchCards)
    const FetchCurrency = StoreCurrency((state) => state.FetchCurrency)
    const FetchCommission = StoreCommissions((state) => state.FetchCommission)
    useEffect(() => {
        (async () => {
            try{await FetchCurrency()}
            catch (e){}
            try{await FetchUser()}
            catch (e){}
            try{await FetchAccounts()}
            catch (e){}
            try{await FetchCards()}
            catch (e){}
            try{await FetchCommission()}
            catch (e){}
        })();
    }, []);

    return (
        <Router location="/">
            <Header/>
            <FullBasketPopUp/>
            <Routes>
                <Route path="/" element={<Main />}/>
                <Route path="/policy" element={<Politic/>}/>
                <Route path="/profile" element={IsAuth ? <PersonalArea/> : null}/>
                <Route path="*" element={<Error404/>}/>
            </Routes>
            <Footer/>
        </Router>
    );
}

export default App;

