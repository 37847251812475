import styles from "../../../styles/components/SendAllReport.module.scss"
import {StoreSelectLicense, StoreUser} from "../../../store/store";
import {SelectReportAccountsWrap} from "./SelectReportAccounts";

export const SendAllReport = () => {
    const SetIsOpenSelectAccount = StoreUser((state) => state.SetIsOpenSelectAccount)
    const SetIsOpenSelectLicense = StoreSelectLicense((state) => state.SetIsOpen)
    const Limits = StoreUser((state) => state.Limits)
    return (
        <>
            <SelectReportAccountsWrap/>
            <div className={styles.send_all_report}>
                <div className={styles.send_all_report_wrap}>
                    <span className={styles.report_accounts_span}>Отправить отчет по всем аккаунтам.</span>
                    <div className={styles.download_reports_wrap} onClick={() => Limits["lvl"] === "free" ? SetIsOpenSelectLicense(true) : SetIsOpenSelectAccount(true)}>
                        <span className={styles.download_reports_span}>
                            ОТПРАВИТЬ
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}