export const ValidateTradeLink = (tradeLink) => {
    const tradeLinkRegex = /^https?:\/\/steamcommunity\.com\/tradeoffer\/new\/\?partner=\d+&token=[a-zA-Z0-9-_]+$/; // Регулярное выражение для проверки формата Trade Link в Steam
    return tradeLinkRegex.test(tradeLink);
}

export const ValidateTelegram = (telegram) => {
    const telegramRegex = /^(@\w+|https?:\/\/t\.me\/\w+)$/; // Регулярное выражение для проверки формата Telegram
    return telegramRegex.test(telegram);
}

export const ValidateEmail = (email) => {
    const emailRegex =  /^[a-z0-9]+[\._]?[a-z0-9]+[@]\w+[.]\w{2,3}$/; // Регулярное выражение для проверки формата Email
    return emailRegex.test(email);
}

export const ValidateQiwi = (wallet) => {
    const walletRegex = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/; // Регулярное выражение для проверки номера телефона
    const cardRegex = /^\d{16}$/; // Регулярное выражение для проверки номера банковской карты
    return walletRegex.test(wallet) || cardRegex.test(wallet.replace(/\s/g, ''));
}

export const ValidateTRC20 = (address) => {
    const addressRegex = /^T[1-9A-HJ-NP-Za-km-z]{33}$/; // Регулярное выражение для проверки формата адреса
    return addressRegex.test(address);
}


export const GetFullNameWallet = {
    "qiwi": "QIWI кошелек",
    "card": "CARDS",
    "usdt": "USDT"
}
export const GetFullTypoWallet = {
    "qiwi": "QIWI",
    "card": "CARDS",
    "usdt": "TRC-20"
}
