import {SecureApi, TradeApi} from "./clients";
import {
    AddAccountLink,
    BASIC_URL, BuyLicenseLink,
    CheckGoogleTokenLink, CloseTradeLink, CommissionsLink,
    CreateGoogleTokenLink, CreateTradeLink,
    CurrencyLink, DelAccountLink, DelCardLink,
    GetAccountsLink, GetCardsLink, GetInventoryDeprecatedLink,
    GetInventoryLink,
    LogoutLink,
    ProfileLink,
    RefreshJwtLink, SaveReportLink,
    SaveUserLink, StatusTradeLink,
    UserLink
} from "./api_constants";

export const Logout = async () => {
    await SecureApi.get(LogoutLink, {prefixUrl: BASIC_URL}).json();
}

export const RefreshJwt = async () => {
    const resp = await SecureApi.get(RefreshJwtLink, {prefixUrl: BASIC_URL}).json();
}

export const CreateGoogleToken = async () => {
    const resp = await SecureApi.get(CreateGoogleTokenLink).json();
}

export const CheckGoogleToken = async (Code) => {
    const resp = await SecureApi.get(CheckGoogleTokenLink, {json: {code: Code}}).json();
}

export const GetCurrency = async () => {
    const resp = await TradeApi.get(CurrencyLink).json();
    return resp
}

export const GetInventory = async (AppId, TradeLink=null, SteamId=null) => {
    return await SecureApi.post(GetInventoryLink, {
        json: {
            app_id: AppId,
            steam_id: SteamId,
            trade_link: TradeLink
        }
    }).json()
}


export const GetInventoryDeprecated = async ({AppId, SteamId, Profile}) => {
    return await SecureApi.post(GetInventoryDeprecatedLink, {
        json: {
            app_id: AppId,
            steam_id: SteamId,
            profile: Profile
        }
    }).json()
}


export const Profile = async ({Profile, SteamId}) => {
    return await SecureApi.post(ProfileLink, {json: {steam_id: SteamId, profile_url: Profile}}).json();
}

export const Commission = async () => {
    return await TradeApi.get(CommissionsLink).json();
}

export const GetUserData = async () => {
    return await SecureApi.get(UserLink).json()
}


export const SaveUser = async (Telegram, Email, BankCard, Qiwi, Trc20, tradeLink) => {
    const resp = await SecureApi.post(SaveUserLink, {json: {telegram: Telegram, email: Email, bankCard: BankCard,
                                                            qiwi: Qiwi, trc20: Trc20, tradeLink: tradeLink}}).json();
}


export const GetAccounts = async () => {
    return await SecureApi.get(GetAccountsLink).json()
}

export const GetCards = async () => {
    return await SecureApi.get(GetCardsLink).json()
}

export const DelCard = async (Card) => {
    return await SecureApi.post(DelCardLink, {json: {card: Card}}).json();
}

export const AddAccount = async (Link, SetIsError, setIsOpenAddAccount, SetIsLoading, AddAccountAct) => {
    try {
        SetIsLoading(true)
        const resp = await SecureApi.post(AddAccountLink, {json: {link: Link}}).json();
        if (resp["status"] && resp["add"]){
            AddAccountAct(resp["profile"])
        }
        SetIsError(!resp["status"]);
    }
    catch (err){
        SetIsError(true);
    }

    finally {
        setIsOpenAddAccount(false)
        SetIsLoading(false)
    }
}

export const StatusTrade = async (TradeId, CheckTrade) => {
    return await TradeApi.post(StatusTradeLink, {json: {tradeId: TradeId, checkTrade: CheckTrade}}).json();
}
export const CloseTradeAPI = async (TradeId) => {
    return await SecureApi.post(CloseTradeLink, {json: {tradeId: TradeId}}).json();
}

export const CreateTradeAPI = async ({PaymentType, Qiwi, Trc20, Email,
                                   Items, FullPrice, FinishPrice, CommissionPrice,
                                         TradeLink, LastActiveBankCard}) => {
    let new_items = []
    for (const item of Items) {
        new_items.push({
            "appid": item["appid"],
            "contextid": item["contextid"],
            "assetid": item["assetid"],
            "classid": item["classid"],
            "instanceid": item["instanceid"],
            "market_hash_name": item["full_market_hash_name"],
            "price": item["Skin Sorter"]["prices"]["median"]
        })
        delete item.Items;

    }
    const trade = {
        PaymentType: PaymentType,
        Qiwi: Qiwi,
        Trc20: Trc20,
        Email: Email,
        Items: new_items,
        FullPrice: FullPrice,
        FinishPrice: FinishPrice,
        CommissionPrice: CommissionPrice,
        tradeLink: TradeLink,
        Card: LastActiveBankCard
    }
    return await TradeApi.post(CreateTradeLink, {json: trade}).json();
}
export const DelAccountAPI = async (steamid) => {
    return await SecureApi.post(DelAccountLink, {json: {steam_id: steamid}}).json();
}

export const BuyLicenseAPI = async (lvl) => {
    return await SecureApi.post(BuyLicenseLink, {json: {lvl: lvl}}).json();
}

export const SaveReportAPI = async (accounts) => {
    return await SecureApi.post(SaveReportLink, {json: {steam_id: accounts}}).json();
}