import blue_right_arrow from "../../../assets/svg/blue_right_arrow.svg"
import success_circle from "../../../assets/svg/succes_circle.svg"
import cancel_cubic from "../../../assets/svg/cancel_cubic.svg"
import styles from "../../../styles/components/UserCard.module.scss"
import {HandySvg} from "handy-svg";
import {StoreSelectLicense, StoreUser} from "../../../store/store";
import {BASIC_URL_API, CreateInvoiceLink} from "../../../services/api_constants";
import {LicenseWrap} from "./License";

export const UserCard = () => {
    const SteamData = StoreUser((state) => state.SteamData)
    const Balance = StoreUser((state) => state.Balance)
    const Registration = StoreUser((state) => state.Registration)
    const Limits = StoreUser((state) => state.Limits)
    const SetIsOpen = StoreSelectLicense((state) => state.SetIsOpen)

    return (
        <>
            <LicenseWrap/>
            <section>
                <div className={styles.personal_area_user}>
                    <div className={styles.user}>
                        <div className={styles.sub_user}>
                            <img src={SteamData["avatarfull"]} className={styles.sub_user_img} alt="Man"/>
                            <div className={styles.sub_user_card}>
                                <h2 className={styles.sub_user_card_h2}>{SteamData["personaname"]}</h2>
                                <div className={styles.sub_user_dateRegister}>
                                    <span className={styles.sub_user_card_span}>Дата регистрации:</span>
                                    <span className={styles.sub_user_card_spanDate}>{Registration}</span>
                                </div>
                                <div>
                                    <span className={styles.sub_user_card_span}>Бесплатные загрузки в этом месяце:</span>
                                    <span className={styles.sub_user_card_spanDate}>{Limits["lvl"] === "pro" ? "No limit" : Limits["report"]}</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.user_balance}>
                            <div>
                                <span className={styles.user_sub_balance_span_1}>Баланс:</span>
                                <span className={styles.user_sub_balance_span_2}>{Balance} ₽</span>
                            </div>
                            <div className={styles.user_sub_topUp}>
                                <div className={styles.user_sub_topUp_link}>
                                    <a className={styles.user_sub_topUp_link_a} href={`${BASIC_URL_API}/${CreateInvoiceLink}`}>Пополнить</a>
                                    <HandySvg src={blue_right_arrow} className={styles.user_sub_topUp_link_svg}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.follow_user}>
                        <div>
                            <h2 className={styles.sub_follow_user_h2}>Подписка:</h2>
                        </div>
                        <div className={styles.sub_follow_user_priceAll}>
                            <div className={styles.sub_follow_user_price_1}>
                                <HandySvg src={Limits["lvl"] === "free" ? success_circle : cancel_cubic} className={styles.sub_follow_user_price_svg}/>
                                <span className={styles.sub_follow_user_price_span_1}>0₽ Free</span>
                                <span
                                    className={styles.sub_follow_user_price_span_2Frst}>(30 отчетов в месяц/5 аккаунтов)</span>
                            </div>
                            <div className={styles.sub_follow_user_price_2}>
                                <HandySvg src={Limits["lvl"] === "trade" ? success_circle : cancel_cubic} className={styles.sub_follow_user_price_svg}/>
                                <span className={styles.sub_follow_user_price_span_1}>100₽ Trade</span>
                                <span className={styles.sub_follow_user_price_span_2}>(250 отчетов в месяц/Общий отчет/50 аккаунтов)</span>
                            </div>
                            <div className={styles.sub_follow_user_price_2}>
                                <HandySvg src={Limits["lvl"] === "pro" ? success_circle : cancel_cubic} className={styles.sub_follow_user_price_svg}/>
                                <span className={styles.sub_follow_user_price_span_1}>250₽ Pro</span>
                                <span className={styles.sub_follow_user_price_span_2Last}>(No limit)</span>
                            </div>
                        </div>
                        <div className={styles.user_sub_license_wrap} onClick={() => SetIsOpen(true)}>
                            <a className={styles.user_sub_topUp_link_a}>АКТИВИРОВАТЬ ПОДПИСКУ</a>
                            <HandySvg src={blue_right_arrow} className={styles.user_sub_topUp_link_svg}/>
                        </div>
                    </div>

                </div>
                <div className={styles.select_games}>
                    <span className={styles.select_games_span}>Выберите игры, чтобы включить их в отчет.</span>
                </div>
            </section>
        </>
    );
}