import styles from "../../../styles/components/TopItems.module.scss";
import {HandySvg} from "handy-svg";
import basket from "../../../assets/svg/basket.svg";
import cancel_popup from "../../../assets/svg/cancel_banner.svg";
import {StoreBasket} from "../../../store/store";

export const TopItems = () => {
    const DeleteAllItems = StoreBasket((state) => state.DeleteAllItems)
    return (
        <>
            <div className={styles.header_sub_main_basket_left}>
                <div className={styles.header_sub_main_basket_left_svg_span}>
                    <HandySvg src={basket} className={styles.header_sub_main_basket_left_svgBasket}/>
                    <span className={styles.header_sub_main_basket_left_span}>Корзина</span>
                </div>
                <HandySvg src={cancel_popup} className={styles.header_sub_main_basket_left_svgMobile}/>
                <span className={styles.header_sub_main_basket_left_a} onClick={DeleteAllItems}>Удалить все</span>
            </div>

        </>
    )
}