import {HandySvg} from 'handy-svg';
import cancel_banner from '../../../assets/svg/cancel_input.svg';
import sorting_down from '../../../assets/svg/sorting_down.svg';
import styles from "../../../styles/components/Description.module.scss"
import {useEffect, useState} from "react";
import {SelectCurrency} from "../lk/SelectCurrency";
import {StoreLoadInventory, StoreUser} from "../../../store/store";
import {GetFullNameCurrency, GetPlaceHolderProfile, LoadInventory, LoadProfile} from "../../../utils/inventory";
import {LoadingInventoryPopUp} from "../../modules/LoadingInventory";
import {CreateParamsStemId} from "../../../utils/Steam";

export const Description = () => {
    const SetErrorLoadingPopUp = StoreLoadInventory((state) => state.SetErrorLoadingPopUp)
    const PlaceHolder = GetPlaceHolderProfile()
    const IsAuth = StoreUser((state) => state.IsAuth)
    const Game = StoreLoadInventory((state) => state.Game)
    const Currency = StoreLoadInventory((state) => state.Currency)
    const TradeLink = StoreLoadInventory((state) => state.TradeLink)
    const SetTradeLink = StoreLoadInventory((state) => state.SetTradeLink)
    const SetItems = StoreLoadInventory((state) => state.SetItems)
    const SetInventoryId = StoreLoadInventory((state) => state.SetInventoryId)
    const SetUserImage = StoreLoadInventory((state) => state.SetUserImage)
    const SetUserName = StoreLoadInventory((state) => state.SetUserName)
    const [IsOpenCurrency, SetIsOpenCurrency] = useState(false)
    const SetErrorText = StoreLoadInventory((state) => state.SetErrorText)
    const [isOpenLoading, setIsOpenLoading] = useState(false)

    let start = false
    useEffect(() => {
        if (PlaceHolder.endsWith("...")){
            return
        }
        SetTradeLink(PlaceHolder)
    }, [start])

    const OnLoadInventory = async () => {
        const params = CreateParamsStemId(TradeLink);
        if (params === undefined){
            SetErrorLoadingPopUp(true)
            SetErrorText("Неправильная ссылка, проверьте ссылку на инвентарь")
        }
        setIsOpenLoading(true)
        window.open("https://market.csgo.com/?utm_source=Steaminventory&utm_medium=banner&utm_campaign=site_pc", "_blank", "height=800,width=1550")
        await Promise.all([
            LoadInventory(TradeLink, Game, SetErrorLoadingPopUp, SetItems, SetErrorText, params),
            LoadProfile(TradeLink, SetUserName, SetUserImage, SetInventoryId, params)
        ]);
        setIsOpenLoading(false)
    }
    return (
        <>
            <LoadingInventoryPopUp isOpen={isOpenLoading} setIsOpen={setIsOpenLoading} text={"Парсинг инвентаря займет 1-2 мин."}/>
            <section>
                <div className={styles.basic_text}>
                    <h2 className={styles.basic_text_h2}>
                        <span className={styles.basic_text_span}>SkinSpace Sorter</span> - это сервис, позволяющий узнать стоимость
                        инвентаря по каждой игре
                        из вашего аккаунта в Steam.
                    </h2>

                    <div>
                        <h2 className={styles.sub_basic_text_h2}>
                            Наш сайт позволяет оценить
                            стоимость инвентаря таких игр как:
                            <span className={styles.sub_basic_text_span}> CS:GO,</span> <span className={styles.sub_basic_text_span}>DOTA 2,</span>
                            <span className={styles.sub_basic_text_span}> RUST</span> и других.
                        </h2>

                        <div className={styles.subend_basic_text}>
                            <h2 className={styles.subend_basic_text_h2}>
                                Вставьте в данное поле ссылку на ваш профиль
                                в Steam, Steam ID или ссылку на Маркет
                                и выберите валюту.
                            </h2>
                        </div>

                        <div className={styles.desktop_link_currency}>
                            <div className={styles.input_link}>
                                <div className={styles.sub_input_link}>
                                    <input type="text" placeholder={PlaceHolder}
                                           className={styles.input_promo} value={TradeLink} onChange={(e) => SetTradeLink(e.target.value)}
                                           onKeyPress={async (event) => event.key === 'Enter' ? await OnLoadInventory() : null}/>
                                    <HandySvg src={cancel_banner} className={styles.sub_input_link_svg} onClick={() => SetTradeLink("")}/>
                                </div>
                            </div>

                            <div className="relative cursor-pointer" onClick={() => SetIsOpenCurrency(!IsOpenCurrency)}>
                                <div className={styles.currency}>
                                    <span className={styles.currency_span}>{GetFullNameCurrency[Currency]}</span>
                                    <HandySvg src={sorting_down} className={styles.currency_svg}/>

                                </div>
                                {IsOpenCurrency ? <SelectCurrency onMouseLeave={() => SetIsOpenCurrency(!IsOpenCurrency)}/> : null}
                            </div>
                        </div>
                        <div className={styles.price} onClick={OnLoadInventory}>
                            <span id="logo" className={styles.price_button}>Узнать стоимость</span>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
