import {HandySvg} from 'handy-svg';
import logo from '../../assets/svg/logo.svg';
import vk from '../../assets/svg/vk.svg';
import tg from '../../assets/svg/tg.svg';
import styles from "../../styles/components/Footer.module.scss"
import {Link} from "react-router-dom";

export const Footer = () => {
    return (
        <footer>
            <div className={styles.sub_footer}>
                <HandySvg src={logo} className={styles.sub_footer_svg}/>
                <div className={styles.contact_wrap}>
                    <div>
                        <Link to="/policy">
                            <span className={styles.contact_span}>Политики и соглашение</span>
                        </Link>
                        <div className={styles.contact_wrap_2}>
                            <span className={styles.contact_span}>Связаться с нами:</span>
                            <div className={styles.contact_wrap_1}>
                                <a href="https://t.me/MannCoSupplyCrateKey"><HandySvg src={tg} className={styles.sub_contact_tg}/></a>
                                <a href="https://t.me/TF2keys"><HandySvg src={tg} className={styles.sub_contact_tg}/></a>
                                <a href="https://vk.com/tf2key"><HandySvg src={vk} className={styles.sub_contact_vk}/></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </footer>
    );
}
