import styles from "../../../styles/components/SelectPaymentMethod.module.scss"
import {HandySvg} from "handy-svg";
import cards from "../../../assets/svg/cards.svg";
import qiwi from "../../../assets/svg/qiwi.svg";
import usdt from "../../../assets/svg/usdt.svg";
import {StoreBasket} from "../../../store/store";
import classNames from "classnames";

export const SelectPaymentMethod = () => {
    const SetPaymentType = StoreBasket((state) => state.SetPaymentType)
    const PaymentType = StoreBasket((state) => state.PaymentType)
    return (
        <div className={styles.payment_method}>
            <span className={styles.payment_method_span}>Выберите способ вывода средств</span>
            <div className={styles.payment_method_card}>
                <div className={classNames(styles.payment_method_cards, PaymentType === "card" ? "border-[3px] border-[#3C73DD]" : null)}
                     // onClick={() => SetPaymentType("card")}>
                     onClick={() => alert("Данный метод временно не поддерживается")}>
                    <HandySvg src={cards} className={styles.payment_method_cards_svg}/>
                    <div className={styles.payment_method_cards_options}>
                        <span className={styles.payment_method_cards_options_1}>Cards</span>
                        <span className={styles.payment_method_cards_options_2}>MasterCard,</span>
                        <span className={styles.payment_method_cards_options_2}>VISA, etc.</span>
                    </div>
                </div>
                <div className={classNames(styles.payment_method_qiwi_1, PaymentType === "qiwi" ? "border-[3px] border-[#3C73DD]" : null)}
                     onClick={() => SetPaymentType("qiwi")}>
                    <HandySvg src={qiwi} className={styles.payment_method_qiwi_svg}/>
                    <div className={styles.payment_method_qiwi_options}>
                        <span className={styles.payment_method_qiwi_options_1}>QIWI</span>
                        <span className={styles.payment_method_qiwi_options_2}>Payment</span>
                        <span className={styles.payment_method_qiwi_options_2}>system</span>
                    </div>
                </div>
                <div className={classNames(styles.payment_method_usdt, PaymentType === "usdt" ? "border-[3px] border-[#3C73DD]" : null)}
                     onClick={() => alert("Данный метод временно не поддерживается")}>
                     {/*onClick={() => SetPaymentType("usdt")}>*/}
                    <HandySvg src={usdt} className={styles.payment_method_usdt_svg}/>
                    <div className={styles.payment_method_usdt_options}>
                        <span className={styles.payment_method_usdt_options_1}>USDT</span>
                        <span className={styles.payment_method_usdt_options_2}>TRC-20</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

