import styles from "../../../styles/components/AddNewAccountPopUp.module.scss"
import cancel from "../../../assets/svg/cancel_banner.svg";
import {HandySvg} from "handy-svg";
import {Fragment, useEffect, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {AddAccount} from "../../../services/api";
import {SuccessAddedPopUp} from "./SuccessAddedPopUp";
import {ErrorAddingPopUp} from "./ErrorAddingPopUp";
import {StoreUser} from "../../../store/store";


export const AddNewAccountPopUp = (props) => {
    const Limits = StoreUser((state) => state.Limits)
    const Accounts = StoreUser((state) => state.Accounts)
    const [Link, SetLink] = useState("")
    const [IsError, SetIsError] = useState(null)
    const [IsLoading, SetIsLoading] = useState(null)
    const AddAccountAct = StoreUser((state) => state.AddAccountAct)
    useEffect(() => {
        if (!IsError){
            SetLink(null)
        }
    }, [IsError])
    return (
        <>
            {(Accounts?.length || 0) < Limits["max inventory"] ? <Transition appear show={props.isOpenAddAccount} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => {props.setIsOpenAddAccount(false); SetIsError(null)}}>
                    <div className="fixed inset-0 overflow-y-auto bg-opacity-40 bg-black">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel>
                                    <div className={styles.new_account}>
                                        <div className={styles.header_new_account}>
                                            <HandySvg src={cancel} className={styles.cross_3_new_account} onClick={() => props.setIsOpenAddAccount(false)}/>
                                            <h2 className={styles.prgrf_new_account}>Добавление нового аккаунта</h2>
                                        </div>
                                        <div>
                                            <span className={styles.text_new_account}>Вставьте в данное поле ссылку на профиль в Steam, Steam ID или Трейд ссылку.</span>
                                            <input type="text" placeholder="https://steamcommunity.com/profiles/.../"
                                                   className={styles.input_promo_new_account} value={Link} onChange={(e) => SetLink(e.target.value)}/>
                                            <span id="logo" className={styles.login_via_steam} onClick={() => IsLoading ? null : AddAccount(Link, SetIsError, props.setIsOpenAddAccount, SetIsLoading, AddAccountAct)}>ДОБАВИТЬ</span>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition> : props.isOpenAddAccount ? <ErrorAddingPopUp CloseMainPopUp={props.setIsOpenAddAccount} text={"Превышены лимиты профиля на максимальное количество аккаунтов."}/> : null}
            {IsError === true ? <ErrorAddingPopUp text={"Проверьте правильность ввода данных и попробуйте снова."}/> : null}
            {IsError === false ? <SuccessAddedPopUp title={"Новый аккаунт успешно добавлен!"} desc={"Вы сможете найти его внизу списка всех отслеживаемых аккаунтов."}/> : null}
        </>
    )
}