import {TradelinkToSteamID} from "steamid-helpers";

export const getSteamIdFromUrl = url => {
    const steamIdRegex = /profiles\/(\d+)/;
    const match = url.match(steamIdRegex);

    if (match && match[1]) {
        return match[1];
    } else {
        return null;
    }
};

export const CreateParamsStemId = (TradeLink) => {
    const params = {};
    if (!isNaN(TradeLink) && TradeLink.length === 17) {
        params["SteamId"] = TradeLink;
    }
    else if (TradeLink.includes("tradeoffer")) {
        params["SteamId"] = TradelinkToSteamID(TradeLink);
    } else if (TradeLink.includes("id")) {
        params["Profile"] = TradeLink;
    } else if (TradeLink.includes("profiles")) {
        params["SteamId"] = getSteamIdFromUrl(TradeLink);
    } else {
        return undefined;
    }
    return params;
};


